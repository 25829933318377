/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { filter } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Paper,
  TableContainer,
  DialogActions,
  Table,
  TextField,
  Button,
  TableRow,
  TableCell,
  TablePagination,
  TableBody,
  Stack,
  Grid,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  MenuItem,
  DialogContent,
  IconButton,
} from '@mui/material';

import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import Scrollbar from '../components/scrollbar';
import Iconify from '../components/iconify';
import { getUserGroups } from '../utils';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import {
  createClientAccessCredentials,
  setPracticeData,
  getClientAccessCredentials,
  editClientAccessCredentials,
  deleteClientAccessCredentials,
  getPracticeData,
  uploadClientW9Copy,
  getClientW9Copy,
  getClientSteps,
} from '../services/api';

const TABLE_HEAD = [
  { id: 'Platform Name', label: 'Platform Name', alignRight: false },
  { id: 'Name', label: 'Name', alignRight: false },
  { id: 'URL', label: 'URL', alignRight: false },
  { id: 'Username', label: 'Username', alignRight: false },
  { id: 'Password', label: 'Password', alignRight: false },
  { id: 'Remarks', label: 'Remarks', alignRight: false },
  { id: 'Actions', label: ' ', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const validationSchemaClient = yup.object({
  platform_name: yup
    .string('Enter the platform name')
    .required('Platform name is required')
    .max(100, `Platform name can't be more than 100 characters`),
  url: yup.string('Enter URL').required('URL is required').max(100, `URL can't be more than 100 characters`),
  name: yup.string('Enter name').required('Name is required').max(100, `Name can't be more than 100 characters`),
  username: yup
    .string('Enter the username')
    .required('Username is required')
    .max(100, `Username can't be more than 100 characters`),
  password: yup
    .string('Enter the password')
    .required('Password is required')
    .max(100, `Password can't be more than 100 characters`),
  remarks: yup.string('Enter the remarks').max(500, `Remarks can't be more than 50 characters`),
});

// const validationSchemaPracticeData = yup.object({
//   npi: yup.string('Enter the NPI').max(100, `NPI can't be more than 100 characters`),
//   taxonomy: yup.string('Enter the taxonomy').max(100, `Taxonomy can't be more than 100 characters`),
//   point_of_contact_person: yup
//     .string('Enter the Point Of Contact Person')
//     .max(100, `Point Of Contact Person can't be more than 100 characters`),
//   w9_copy_s3_key: yup.string('Enter the name').max(100, `Name can't be more than 100 characters`),
// });

const validationSchemaEHR = yup.object({
  name: yup.string('Enter the name').max(100, `Name can't be more than 100 characters`).required('Name is required'),
  url: yup.string('Enter the URL').max(100, `URL can't be more than 100 characters`).required('URL is required'),
  username: yup
    .string('Enter the usernname')
    .required('Username is required')
    .max(100, `Username can't be more than 100 characters`),
  password: yup
    .string('Enter the Password')
    .required('Password is required')
    .max(100, `Password can't be more than 100 characters`),
  platform_name: yup
    .string('Enter the platform')
    .required('Platform is required')
    .max(100, `Platform can't be more than 100 characters`),
});

const validationSchemaEMR = yup.object({
  name: yup.string('Enter the name').max(100, `Name can't be more than 100 characters`).required('Name is required'),
  url: yup.string('Enter the URL').max(100, `URL can't be more than 100 characters`).required('URL is required'),
  username: yup
    .string('Enter the usernname')
    .required('Usernname is required')
    .max(100, `Username can't be more than 100 characters`),
  password: yup
    .string('Enter the Password')
    .required('Password is required')
    .max(100, `Password can't be more than 100 characters`),
  platform_name: yup
    .string('Enter the platform')
    .required('Platform is required')
    .max(100, `Platform can't be more than 100 characters`),
});

function applySortFilter(array = [{}], comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PracticeInfoPage() {
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState('');
  const [fileUploadingOrDownloading, setFileUploadingOrDownloading] = useState(false);
  const [deleteCredentialsModalOpen, setDeleteCredentialsModalOpen] = useState(false);
  const [addCredentialsModalOpen, setCredentialsModalOpen] = useState(false);
  const [isEditSelected, setIsEditSelected] = useState(false);
  const [accessCredentials, setAccessCredentials] = useState([]);
  const [selectedClientCredentials, setSelectedClientCredentials] = useState('');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = accessCredentials.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredAccessCredentials?.length) : 0;

  const filteredAccessCredentials = applySortFilter(accessCredentials, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredAccessCredentials.length && !!filterName;

  const navigate = useNavigate();

  const handleW9CopyDownload = async () => {
    await getClientW9Copy()
      .then((response) => {
        window.open(response.data.download_url, '_blank');
      })
      .catch(() => {
        const message = 'Error downloading W9 copy. Try again later.';
        toast.error(message);
      });
  };
  const handleW9CopyUpload = async (event) => {
    const file = event.target.files[0];
    if (file.type === 'application/pdf' && file.size <= 10000000) {
      setFileUploadingOrDownloading(true);
      const formData = new FormData();
      formData.append('w9_copy', file);
      await uploadClientW9Copy(formData)
        .then((response) => {
          const message = 'W9 Copy successfully uploaded.';
          formikPracticeData.setFieldValue('w9_copy_s3_key', response.data.w9_copy_s3_key);
          toast.success(message);
        })
        .catch(() => {
          const message = 'Error uploading W9 copy. Try again later.';
          toast.error(message);
        })
        .finally(() => {
          setFileUploadingOrDownloading(false);
        });
    } else {
      const message = 'Invalid file size or type. Try again later.';
      toast.error(message);
    }
  };

  const handleDeleteCredentialsModalOpen = (row) => {
    setSelectedClientCredentials(row);
    setDeleteCredentialsModalOpen(true);
  };

  const handleFieldBlurEHR = (fieldName) => {
    formikEHR.setFieldTouched(fieldName, true, true);
  };

  const handleFieldBlurEMR = (fieldName) => {
    formikEMR.setFieldTouched(fieldName, true, true);
  };

  const handleAddCredentialsModalClose = () => {
    setCredentialsModalOpen(false);
    setSelectedClientCredentials('');
  };

  const formikPracticeData = useFormik({
    initialValues: {
      npi: '',
      taxonomy: '',
      point_of_contact_person: '',
      w9_copy_s3_key: '',
    },
    onSubmit: async (values) => {
      try {
        setLoading('practiceData');
        await setPracticeData(values)
          .then(() => {
            const message = 'Practice info successfully saved.';
            toast.success(message);
          })
          .catch(() => {
            const message = 'Error saving practice info. Try again later.';
            toast.error(message);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        const message = 'Error saving practice info. Try again later.';
        toast.error(message);
        setLoading(false);
      }
    },
  });

  const formikEHR = useFormik({
    initialValues: {
      id: '',
      username: '',
      platform_name: 'EHR',
      password: '',
      url: '',
      name: '',
      remarks: '',
    },
    validationSchema: validationSchemaEHR,
    onSubmit: async (values) => {
      try {
        setLoading('ehr');
        if (values.id === '' || values.id === undefined) {
          await createClientAccessCredentials(values);
          fetchEHRCredentials();
        } else {
          await editClientAccessCredentials(values);
        }
        const message = 'Practice info successfully saved.';
        toast.success(message);
        setLoading(false);
      } catch (error) {
        const message = 'Error saving practice info. Try again later.';
        toast.error(message);
        setLoading(false);
      }
    },
  });

  const formikEMR = useFormik({
    initialValues: {
      id: '',
      username: '',
      platform_name: 'EMR',
      password: '',
      url: '',
      name: '',
      remarks: '',
    },
    validationSchema: validationSchemaEMR,
    onSubmit: async (values) => {
      try {
        setLoading('emr');
        if (values.id === '' || values.id === undefined) {
          await createClientAccessCredentials(values);
          fetchEMRCredentials();
        } else {
          await editClientAccessCredentials(values);
        }
        const message = 'Practice info successfully saved.';
        toast.success(message);
        setLoading(false);
      } catch (error) {
        const message = 'Error saving practice info. Try again later.';
        toast.error(message);
        setLoading(false);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      platform_name: '',
      url: '',
      username: '',
      password: '',
      remarks: '',
    },
    validationSchema: validationSchemaClient,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (values.id === '' || values.id === undefined) {
          await createClientAccessCredentials(values);
        } else {
          await editClientAccessCredentials(values);
        }
        const message = 'Practice info successfully saved.';
        toast.success(message);
        setLoading(false);
        handleAddCredentialsModalClose();
      } catch (error) {
        const message = 'Error saving practice info. Try again later.';
        toast.error(message);
        setLoading(false);
        handleAddCredentialsModalClose();
      }
    },
  });

  const handleFieldBlur = (fieldName) => {
    formik.setFieldTouched(fieldName, true, true);
  };

  const handleAddCredentialsModalOpen = () => {
    setCredentialsModalOpen(true);
    setIsEditSelected(false);
    setSelectedClientCredentials('');
    formik.resetForm();
  };

  const handleEditCredentialsModalOpen = (row) => {
    setSelectedClientCredentials(row);
    setCredentialsModalOpen(true);
    setIsEditSelected(true);
  };

  const handleDeleteCredentials = () => {
    setDeleteLoader(true);
    deleteClientAccessCredentials({ id: selectedClientCredentials.id })
      .then(() => {
        const message = 'Credentials successfully deleted.';
        toast.success(message);
      })
      .catch(() => {
        const message = 'Error deleting credentials. Try again later.';
        toast.error(message);
      })
      .finally(() => {
        setDeleteLoader(false);
        setDeleteCredentialsModalOpen(false);
        setSelectedClientCredentials('');
      });
  };

  const fetchEHRCredentials = () => {
    setDataLoading(true);
    getClientAccessCredentials({ platform_name: 'EHR' })
      .then((response) => {
        if (response.data.credentials.length > 0) {
          formikEHR.setValues(response.data.credentials[0]);
        }
      })
      .catch(() => {
        const message = 'Cannot get client access credentials, try again later.';
        toast.error(message);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  const fetchEMRCredentials = () => {
    setDataLoading(true);
    getClientAccessCredentials({ platform_name: 'EMR' })
      .then((response) => {
        if (response.data.credentials.length > 0) {
          formikEMR.setValues(response.data.credentials[0]);
        }
      })
      .catch(() => {
        const message = 'Cannot get client access credentials, try again later.';
        toast.error(message);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    setDataLoading(true);

    const userGroups = getUserGroups();
    if (!userGroups.includes('client')) {
      navigate('/dashboard', { replace: true });
    }

    if (selectedClientCredentials !== '') {
      formik.setValues(selectedClientCredentials);
    }

    Promise.all([
      getClientSteps()
        .then((clientSteps) => {
          // if client step with name Sign Agreement exists and its status is To Do, navigate to sign-agreement page
          if (clientSteps.data.find((step) => step.step.name === 'Sign Agreement' && step.status === 'To Do')) {
            toast.error('Please sign the agreement first.');
            navigate('/sign-agreement', { replace: true });
          }
        })
        .catch(() => {
          const message = 'Cannot get client steps, try again later.';
          toast.error(message);
        }),

      getClientAccessCredentials()
        .then((response) => {
          // remove all response.data.credentials item except platform name EHR, EMR
          if (response.data.credentials.length > 0) {
            const credentials = response.data.credentials.filter(
              (cred) => cred.platform_name !== 'EHR' && cred.platform_name !== 'EMR'
            );
            setAccessCredentials(credentials);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error('Unable to get client access credentials.');
        }),

      getPracticeData()
        .then((practiceData) => {
          formikPracticeData.setValues(practiceData.data.practice_data);
        })
        .catch(() => {
          const message = 'Cannot get practice data, try again later.';
          toast.error(message);
        }),

      fetchEHRCredentials(),
      fetchEMRCredentials(),
    ]).finally(() => {
      setDataLoading(false);
    });
  }, [selectedClientCredentials, addCredentialsModalOpen]);

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | Practice Info</title>
      </Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              Practice Information
            </Typography>
          </Stack>
        </Grid>
        <Grid container spacing={3} pl={3} justifyContent={'center'}>
          <Grid item xs={12} md={8} lg={4}>
            <Card sx={{ maxWidth: 500, padding: 5 }}>
              <Grid>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                  <Typography variant="h6" gutterBottom>
                    Practice Data
                  </Typography>
                </Stack>
                {dataLoading ? (
                  <Stack alignItems="center" justifyContent="center" height="100%">
                    <CircularProgress />
                  </Stack>
                ) : (
                  <form onSubmit={formikPracticeData.handleSubmit}>
                    <Stack spacing={2}>
                      <TextField
                        id="npi"
                        name="npi"
                        label="NPI"
                        value={formikPracticeData.values.npi}
                        onChange={formikPracticeData.handleChange}
                        // onBlur={() => handleFieldBlurEHR('npi')}
                        error={formikPracticeData.touched.npi && Boolean(formikPracticeData.errors.npi)}
                        helperText={formikPracticeData.touched.npi && formikPracticeData.errors.npi}
                      />
                      <TextField
                        id="taxonomy"
                        name="taxonomy"
                        label="Taxonomy"
                        value={formikPracticeData.values.taxonomy}
                        onChange={formikPracticeData.handleChange}
                        // onBlur={() => handleFieldBlurEHR('taxonomy')}
                        error={formikPracticeData.touched.taxonomy && Boolean(formikPracticeData.errors.taxonomy)}
                        helperText={formikPracticeData.touched.taxonomy && formikPracticeData.errors.taxonomy}
                      />
                      <TextField
                        id="point_of_contact_person"
                        name="point_of_contact_person"
                        label="Point Of Contact Person"
                        value={formikPracticeData.values.point_of_contact_person}
                        onChange={formikPracticeData.handleChange}
                        // onBlur={() => handleFieldBlurEHR('point_of_contact_person')}
                        error={
                          formikPracticeData.touched.point_of_contact_person &&
                          Boolean(formikPracticeData.errors.point_of_contact_person)
                        }
                        helperText={
                          formikPracticeData.touched.point_of_contact_person &&
                          formikPracticeData.errors.point_of_contact_person
                        }
                      />
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          type="file"
                          id="fileUpload"
                          name="fileUpload"
                          onChange={handleW9CopyUpload}
                          label="W9 Copy Upload"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                        {formikPracticeData.values.w9_copy_s3_key ? (
                          <LoadingButton
                            variant="outlined"
                            loading={loading === 'practiceData' || fileUploadingOrDownloading}
                            startIcon={
                              loading === 'practiceData' || fileUploadingOrDownloading ? (
                                <CircularProgress size={20} />
                              ) : null
                            }
                            loadingPosition="start"
                            style={{ marginLeft: '8px' }}
                            sx={{ borderColor: '#0A9EF2', color: '#0A9EF2' }}
                            onClick={handleW9CopyDownload}
                          >
                            Download
                          </LoadingButton>
                        ) : null}
                      </div>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="start" mb={5} mt={5}>
                      <Typography variant="h4" gutterBottom />
                      <LoadingButton
                        sx={{
                          backgroundColor: '#0A9EF2',
                        }}
                        type="submit"
                        variant="contained"
                        loading={loading === 'practiceData' || fileUploadingOrDownloading}
                        loadingPosition="start"
                        startIcon={
                          loading === 'practiceData' || fileUploadingOrDownloading ? (
                            <CircularProgress size={20} />
                          ) : null
                        }
                      >
                        Save
                      </LoadingButton>
                    </Stack>
                  </form>
                )}
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={8} lg={4}>
            <Card sx={{ maxWidth: 500, padding: 5 }}>
              <Grid>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                  <Typography variant="h6" gutterBottom>
                    EHR
                  </Typography>
                </Stack>
                {dataLoading ? (
                  <Stack alignItems="center" justifyContent="center" height="100%">
                    <CircularProgress />
                  </Stack>
                ) : (
                  <form onSubmit={formikEHR.handleSubmit}>
                    <Stack spacing={2}>
                      <TextField
                        id="name"
                        name="name"
                        label="Name"
                        value={formikEHR.values.name}
                        onChange={formikEHR.handleChange}
                        onBlur={() => handleFieldBlurEHR('name')}
                        error={formikEHR.touched.name && Boolean(formikEHR.errors.name)}
                        helperText={formikEHR.touched.name && formikEHR.errors.name}
                      />
                      <TextField
                        id="url"
                        name="url"
                        label="URL"
                        value={formikEHR.values.url}
                        onChange={formikEHR.handleChange}
                        onBlur={() => handleFieldBlurEHR('url')}
                        error={formikEHR.touched.url && Boolean(formikEHR.errors.url)}
                        helperText={formikEHR.touched.url && formikEHR.errors.url}
                      />
                      <TextField
                        id="username"
                        name="username"
                        label="Username"
                        value={formikEHR.values.username}
                        onChange={formikEHR.handleChange}
                        onBlur={() => handleFieldBlurEHR('username')}
                        error={formikEHR.touched.username && Boolean(formikEHR.errors.username)}
                        helperText={formikEHR.touched.username && formikEHR.errors.username}
                      />
                      <TextField
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        value={formikEHR.values.password}
                        onChange={formikEHR.handleChange}
                        onBlur={() => handleFieldBlurEHR('password')}
                        error={formikEHR.touched.password && Boolean(formikEHR.errors.password)}
                        helperText={formikEHR.touched.password && formikEHR.errors.password}
                      />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="start" mb={5} mt={5}>
                      <Typography variant="h4" gutterBottom />
                      <LoadingButton
                        sx={{
                          backgroundColor: '#0A9EF2',
                        }}
                        type="submit"
                        variant="contained"
                        loading={loading ? 'ehr' : false}
                        loadingPosition="start"
                        startIcon={loading === 'ehr' ? <CircularProgress size={20} /> : null}
                      >
                        Save
                      </LoadingButton>
                    </Stack>
                  </form>
                )}
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={8} lg={4}>
            <Card sx={{ maxWidth: 500, padding: 5 }}>
              <Grid>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                  <Typography variant="h6" gutterBottom>
                    EMR
                  </Typography>
                </Stack>
                {dataLoading ? (
                  <Stack alignItems="center" justifyContent="center" height="100%">
                    <CircularProgress />
                  </Stack>
                ) : (
                  <form onSubmit={formikEMR.handleSubmit}>
                    <Stack spacing={2}>
                      <TextField
                        id="name"
                        name="name"
                        label="Name"
                        value={formikEMR.values.name}
                        onChange={formikEMR.handleChange}
                        onBlur={() => handleFieldBlurEMR('name')}
                        error={formikEMR.touched.name && Boolean(formikEMR.errors.name)}
                        helperText={formikEMR.touched.name && formikEMR.errors.name}
                      />
                      <TextField
                        id="url"
                        name="url"
                        label="URL"
                        value={formikEMR.values.url}
                        onChange={formikEMR.handleChange}
                        onBlur={() => handleFieldBlurEMR('url')}
                        error={formikEMR.touched.url && Boolean(formikEMR.errors.url)}
                        helperText={formikEMR.touched.url && formikEMR.errors.url}
                      />
                      <TextField
                        id="username"
                        name="username"
                        label="Username"
                        value={formikEMR.values.username}
                        onChange={formikEMR.handleChange}
                        onBlur={() => handleFieldBlurEMR('username')}
                        error={formikEMR.touched.username && Boolean(formikEMR.errors.username)}
                        helperText={formikEMR.touched.username && formikEMR.errors.username}
                      />
                      <TextField
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        value={formikEMR.values.password}
                        onChange={formikEMR.handleChange}
                        onBlur={() => handleFieldBlurEMR('password')}
                        error={formikEMR.touched.password && Boolean(formikEMR.errors.password)}
                        helperText={formikEMR.touched.password && formikEMR.errors.password}
                      />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="start" mb={5} mt={5}>
                      <Typography variant="h4" gutterBottom />
                      <LoadingButton
                        sx={{
                          backgroundColor: '#0A9EF2',
                        }}
                        type="submit"
                        variant="contained"
                        loading={loading ? 'emr' : false}
                        loadingPosition="start"
                        startIcon={loading === 'emr' ? <CircularProgress size={20} /> : null}
                      >
                        Save
                      </LoadingButton>
                    </Stack>
                  </form>
                )}
              </Grid>
            </Card>
          </Grid>
        </Grid>

        <Dialog open={addCredentialsModalOpen} onClose={handleAddCredentialsModalClose} fullWidth>
          <DialogTitle>{isEditSelected ? 'Edit Credentials' : 'Add Credentials'}</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <form onSubmit={formik.handleSubmit}>
                  <Stack spacing={1} paddingTop={1}>
                    <TextField
                      id="platform_name"
                      name="platform_name"
                      label="Platform Name"
                      select
                      value={formik.values.platform_name}
                      onChange={formik.handleChange}
                      onBlur={() => handleFieldBlur('platform_name')}
                      error={formik.touched.platform_name && Boolean(formik.errors.platform_name)}
                      helperText={formik.touched.platform_name && formik.errors.platform_name}
                    >
                      <MenuItem key="Availity" value="Availity">
                        Availity
                      </MenuItem>
                      <MenuItem key="Optum Pay" value="Optum Pay">
                        Optum Pay
                      </MenuItem>
                      <MenuItem key="United Healthcare" value="United Healthcare">
                        United Healthcare
                      </MenuItem>
                      <MenuItem key="Cigna Health Plan" value="Cigna Health Plan">
                        Cigna Health Plan
                      </MenuItem>
                      <MenuItem key="Medicare" value="Medicare">
                        Medicare
                      </MenuItem>
                      <MenuItem key="Medicaid" value="Medicaid">
                        Medicaid
                      </MenuItem>
                      <MenuItem key="NPPES" value="NPPES">
                        NPPES
                      </MenuItem>
                      <MenuItem key="CAQH" value="CAQH">
                        CAQH
                      </MenuItem>
                      <MenuItem key="Other" value="Other">
                        Other
                      </MenuItem>
                    </TextField>
                    <TextField
                      id="name"
                      name="name"
                      label="Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={() => handleFieldBlur('name')}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField
                      id="url"
                      name="url"
                      label="URL"
                      value={formik.values.url}
                      onChange={formik.handleChange}
                      onBlur={() => handleFieldBlur('url')}
                      error={formik.touched.url && Boolean(formik.errors.url)}
                      helperText={formik.touched.url && formik.errors.url}
                    />
                    <TextField
                      id="username"
                      name="username"
                      label="Username"
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      onBlur={() => handleFieldBlur('username')}
                      error={formik.touched.username && Boolean(formik.errors.username)}
                      helperText={formik.touched.username && formik.errors.username}
                    />
                    <TextField
                      id="password"
                      name="password"
                      label="Password"
                      type="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={() => handleFieldBlur('password')}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                    />
                    <TextField
                      id="remarks"
                      name="remarks"
                      label="Remarks"
                      value={formik.values.remarks}
                      onChange={formik.handleChange}
                      onBlur={() => handleFieldBlur('remarks')}
                      error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                      helperText={formik.touched.remarks && formik.errors.remarks}
                    />
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5} spacing="5px">
                      <Button onClick={handleAddCredentialsModalClose} color="primary">
                        Cancel
                      </Button>
                      <Typography variant="h4" gutterBottom />
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={loading}
                        loadingPosition="start"
                        startIcon={loading ? <CircularProgress size={20} /> : null}
                      >
                        {isEditSelected ? 'Edit' : 'Save'}
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </form>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog open={deleteCredentialsModalOpen} onClose={() => setDeleteCredentialsModalOpen(false)}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to delete the selected credentials?</p>
          </DialogContent>
          <DialogActions sx={{ padding: 2 }}>
            <Button onClick={() => setDeleteCredentialsModalOpen(false)} color="primary">
              Cancel
            </Button>
            <LoadingButton
              onClick={handleDeleteCredentials}
              color="primary"
              variant="contained"
              loading={deleteLoader}
              loadingPosition="start"
              startIcon={deleteLoader ? <CircularProgress size={20} /> : null}
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <Grid item xs={12} md={12} lg={12}>
          <Grid item xs={12} md={8} lg={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
              <Typography variant="h4" gutterBottom>
                Other Platforms
              </Typography>
              <Button
                variant="contained"
                onClick={() => handleAddCredentialsModalOpen(true)}
                startIcon={<Iconify icon="eva:plus-fill" />}
                style={{
                  backgroundColor: '#0A9EF2',
                  padding: 10,
                }}
              >
                Add Credentials
              </Button>
            </Stack>
          </Grid>
          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                {dataLoading ? (
                  <Stack alignItems="center" justifyContent="center" height="100%">
                    <CircularProgress />
                  </Stack>
                ) : (
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={filteredAccessCredentials.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredAccessCredentials
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const { id, name, username, url, platform_name, remarks } = row;
                          const selectedUser = selected.indexOf(platform_name) !== -1;

                          if (row.platform_name === 'EHR' || row.platform_name === 'EMR') {
                            return null;
                          }

                          return (
                            <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                              <TableCell align="left" />
                              <TableCell align="left">{platform_name}</TableCell>
                              <TableCell align="left">{name}</TableCell>
                              <TableCell align="left">{url}</TableCell>
                              <TableCell align="left">{username}</TableCell>
                              <TableCell align="left">*********</TableCell>
                              <TableCell align="left">{remarks}</TableCell>
                              <TableCell align="left">
                                <IconButton
                                  onClick={() => handleEditCredentialsModalOpen(row)}
                                  aria-label="edit"
                                  color="primary"
                                >
                                  <Iconify icon="eva:edit-2-fill" />
                                </IconButton>
                                <IconButton
                                  aria-label="delete"
                                  color="error"
                                  onClick={() => handleDeleteCredentialsModalOpen(row)}
                                >
                                  <Iconify icon="eva:trash-2-fill" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                )}
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={accessCredentials?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
