import * as jose from 'jose';

export const getUserGroups = () => {
  try {
    const accessToken = localStorage.getItem('access');
    const decodedToken = jose.decodeJwt(accessToken);
    if (decodedToken) {
      return decodedToken.groups;
    }
    return null;
  } catch (error) {
    // Error parsing token, handle accordingly
    console.log('invalid token here');
    return null;
  }
};
