import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import { PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import dayjs from 'dayjs';

dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: '#fff !important',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    color: '#fff !important',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    color: '#fff !important',
  }),
}));
export default function BasicDatePicker(props) {
  const { value, open, setValue, onClose, type = 'start', analytics = false, tabValue = 0 } = props;

  const minDate = type !== 'start' && value?.startValue ? dayjs(value.startValue) : null;

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = value.startValue;

    const dayIsBetween = date.isBetween(start, start, null, '[]');
    const isFirstDay = date.isSame(start, 'day');

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isFirstDay}
      />
    );
  };

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} open={open}>
      <DialogContent>
        <DialogTitle>Select {type} date</DialogTitle>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={null}
            onChange={(newValue) => {
              setValue(newValue, type);
            }}
            minDate={minDate}
            renderInput={(params) => <TextField {...params} />}
            renderDay={type !== 'start' ? renderWeekPickerDay : undefined}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            fontWeight: 500,
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
