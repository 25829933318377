import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Container, Button, CircularProgress, Stack, FormControlLabel, Checkbox } from '@mui/material';
import { toast } from 'react-toastify';
import { Document, Page, pdfjs } from 'react-pdf';
import DownloadIcon from '@mui/icons-material/Download';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { getClientContractFile, signClientContract } from '../services/api';
import { getUserGroups } from '../utils';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

export default function ShowContractPage() {
  const navigate = useNavigate();
  const [contractFileUrl, setContractFileUrl] = useState();
  const [contractSignStatus, setContractSignStatus] = useState(null);
  const [agreed, setAgreed] = useState(false);
  const [numPages, setNumPages] = useState();
  const [loading, setLoading] = useState(true);
  const [userGroups, setUserGroups] = useState([]);
  const { id } = useParams();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleAgreeChange = (event) => {
    setAgreed(event.target.checked);
  };

  const handleContractSign = () => {
    signClientContract({ id })
      .then(() => {
        toast.success('Contract signed successfully.');
        navigate(`/dashboard`, { replace: true });
      })
      .catch(() => {
        const message = 'Unable to sign contract, try again later.';
        console.log(message);
        toast.error(message);
      });
  };

  useEffect(() => {
    setUserGroups(getUserGroups());
    getClientContractFile({ id })
      .then((response) => {
        // eslint-disable-next-line camelcase
        const { url, sign_status } = response.data;
        setContractFileUrl(url);
        setContractSignStatus(sign_status);
      })
      .catch(() => {
        const message = 'Cannot get the contract file, try again later.';
        console.log(message);
        toast.error(message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleContractDownload = () => {
    if (contractFileUrl !== null) {
      window.open(contractFileUrl, '_blank');
    }
  };

  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | Agreement</title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12} />
          {loading ? (
            <>
              <Grid container item xs={12} justifyContent="center">
                <Stack spacing={1}>
                  <CircularProgress />
                </Stack>
              </Grid>
            </>
          ) : null}
          {!loading && contractFileUrl !== null ? (
            <>
              <div style={{ width: '100%', maxWidth: '900px', margin: '0 auto', overflow: 'auto', maxHeight: '600px' }}>
                <Document
                  file={contractFileUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={(error) => {
                    console.error('Error while loading the document:', error);
                    toast.error('Error while loading the document. Please try again later.');
                  }}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} width={900} />
                  ))}
                </Document>
              </div>
              {contractSignStatus !== null && contractSignStatus !== 'Signed' && userGroups.includes('client') ? (
                <>
                  <Grid container item xs={12} justifyContent="center">
                    <FormControlLabel
                      control={<Checkbox checked={agreed} onChange={handleAgreeChange} />}
                      label="I agree to the terms and conditions of this contract"
                    />
                  </Grid>
                  <Grid container item xs={12} justifyContent="center">
                    <Stack direction="row" spacing={2} justifyContent="center">
                      <Button variant="contained" disabled={!agreed} onClick={handleContractSign} color="primary">
                        Submit
                      </Button>
                      <Button
                        startIcon={<DownloadIcon />}
                        variant="contained"
                        onClick={handleContractDownload}
                        color="primary"
                      >
                        Download
                      </Button>
                    </Stack>
                  </Grid>
                </>
              ) : (
                <Grid container item xs={12} justifyContent="center">
                  <Button
                    startIcon={<DownloadIcon />}
                    variant="contained"
                    onClick={handleContractDownload}
                    color="primary"
                  >
                    Download
                  </Button>
                </Grid>
              )}
            </>
          ) : null}
        </Grid>
      </Container>
    </>
  );
}
