import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Button } from '@mui/material';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import { useVerifyDocumentMutation } from '../services/api';
// components
import Logo from '../components/logo';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const override = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 'auto',
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const { id } = useParams(); // Extract the 'id' URL parameter
  const [response, setResponse] = useState(null);
  const [verifyDocument] = useVerifyDocumentMutation();

  useEffect(() => {
    // Send the API request when the component mounts
    verifyDocument({ unique_code: id })
      .unwrap() // Unwrap the response from the mutation
      .then((data) => {
        // Store the response in the state
        setResponse(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [id, verifyDocument]);

  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | VeriDoc</title>
      </Helmet>
      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container maxWidth="sm">
          <StyledContent>
            {response !== null ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100vh',
                  marginTop: '-140px',
                  // Adjust this value as needed to fine-tune the vertical alignment
                }}
              >
                <img
                  src={`/assets/images/${response.status === 'found' ? 'verified' : 'not_verified'}.png`}
                  alt="Verified"
                  style={{ width: '30%' }}
                />
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '30px', // Adjust the font size for smaller screens
                    lineHeight: '28px', // Adjust the line height for smaller screens
                    marginTop: '20px', // Adjust the top margin for smaller screens
                    marginBottom: '10px', // Adjust the bottom margin for smaller screens
                    textAlign: 'center', // Center align the text for smaller screens
                  }}
                >
                  {response.status === 'found' ? 'This document is verified.' : 'This document could not be verified.'}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ margin: '20px' }}
                  href="https://billerbay.com"
                  target="_blank"
                >
                  Visit BillerBay
                </Button>
              </Box>
            ) : (
              <BeatLoader
                color={'#1cadff'}
                loading
                cssOverride={override}
                size={25}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
          </StyledContent>
        </Container>
      </StyledRoot>{' '}
    </>
  );
}
