import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Menu,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Iconify from '../components/iconify';
import { getUserGroups } from '../utils';
import { getSupportTickets, closeSupportTicket } from '../services/api';

function DataTable({
  handleClick,
  data,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  isClient,
  setReload,
  loading,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const TABLE_HEAD = [
    { id: 'created', label: 'Created', alignRight: false },
    { id: 'username', label: 'Username', alignRight: false },
    { id: 'subject', label: 'Subject', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'last_updated', label: 'Last Updated', alignRight: false },
    { id: '' },
  ];

  const handleMenuOpen = (event, ticket) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedTicket(ticket);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedTicket(null);
  };

  const handleCloseTicket = async (event) => {
    event.stopPropagation();

    await closeSupportTicket({ id: selectedTicket })
      .then(() => {
        const message = 'Ticket closed successfully.';
        toast.success(message);
      })
      .catch((error) => {
        console.log('error', error);
        toast.error('Unable to close the ticket. Try again later.');
      })
      .finally(() => {
        handleMenuClose();
        setReload((prevReload) => prevReload + 1);
      });
  };

  function formatDate(createdAt) {
    const date = new Date(createdAt);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'GMT',
      timeZoneName: 'short',
    };
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  }

  return (
    <>
      <Box padding={2}>
        <FormControl sx={{ width: 500, marginBottom: 3 }}>
          <InputLabel id="demo-simple-select-label">Select User</InputLabel>
          <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Select User">
            <MenuItem>User</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox />
              </TableCell>
              {TABLE_HEAD.map((headCell) => (
                <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : data.length === 0 ? (
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <Paper
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="body2">No records found.</Typography>
                  </Paper>
                </TableCell>
              </TableRow>
            ) : (
              data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                <TableRow key={item.id} onClick={() => handleClick(item)} style={{ cursor: 'pointer' }}>
                  <TableCell padding="checkbox">
                    <Checkbox onClick={(e) => e.stopPropagation()} />
                  </TableCell>
                  <TableCell align="left">{formatDate(item.updated_at)}</TableCell>
                  <TableCell align="left">{item.created_by}</TableCell>
                  <TableCell align="left">{item.subject}</TableCell>
                  <TableCell align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {item.status === 'Answered' && (
                        <img src="/assets/iconDone.png" alt="answered" height="20px" width="20px" />
                      )}
                      {item.status === 'Open' && (
                        <img src="/assets/iconProcess.png" alt="open" height="20px" width="20px" />
                      )}
                      {item.status === 'Closed' && (
                        <img src="/assets/iconClose.png" alt="closed" height="20px" width="20px" />
                      )}
                      <p style={{ margin: '0px 0px 0px 5px' }}>{item.status}</p>
                    </div>
                  </TableCell>
                  <TableCell align="left">{formatDate(item.updated_at)}</TableCell>
                  {!isClient ? (
                    <TableCell align="right">
                      <IconButton
                        onClick={(e) => handleMenuOpen(e, item.id)}
                        aria-controls="client-actions-menu"
                        aria-haspopup="true"
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="client-actions-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={(e) => handleCloseTicket(e)}>Close Ticket</MenuItem>
                      </Menu>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 15, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default function SupportTicket() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [reload, setReload] = useState(0);
  const [supportTickets, setSupportTickets] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleClick = (item) => {
    if (item.status === 'Closed') return;
    navigate(`/support-tickets/${item.id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setLoading(true);
    const userGroups = getUserGroups();
    if (!userGroups.includes('admin') && !userGroups.includes('associate')) {
      setIsClient(true);
    } else {
      setIsClient(false);
    }
    getSupportTickets()
      .then((response) => {
        setSupportTickets(response.data.tickets);
        setLoading(false);
        // setReload(false);
      })
      .catch(() => {
        toast.error('Unable to get support tickets, try again later.');
      });
  }, [isClient, reload]);

  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | Support Ticket</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Stack marginBottom={7} direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              Support Tickets
            </Typography>
            {isClient ? (
              <Button
                variant="contained"
                onClick={() => navigate('new')}
                startIcon={<Iconify icon="eva:plus-fill" />}
                style={{
                  backgroundColor: '#0A9EF2',
                  padding: 10,
                }}
              >
                New Support Ticket
              </Button>
            ) : null}
          </Stack>
          <Card>
            <DataTable
              handleClick={handleClick}
              data={supportTickets}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              isClient={isClient}
              setReload={setReload}
              loading={loading}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
