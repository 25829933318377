/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, TextField, Checkbox, Typography, Button, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as jose from 'jose';

import { toast } from 'react-toastify';
import { useLoginMutation } from '../../../services/api';

// ----------------------------------------------------------------------

const validationSchema = yup.object({
  username: yup
    .string('Enter your username')
    .required('Username is required')
    .max(100, `Username can't be more than 100 characters.`),
  password: yup
    .string('Enter your password')
    .required('Password is required')
    .max(100, `Password can't be more than 100 characters.`),
});

export default function LoginForm({ setForgotPassword }) {
  const [loading, setLoading] = useState(false);
  const [login] = useLoginMutation();
  const navigate = useNavigate();

  useEffect(() => {
    const rememberMe = localStorage.getItem('rememberMe') === 'true';
    const accessToken = localStorage.getItem('access');

    if (rememberMe && accessToken) {
      navigate('/contracts', { replace: true });
    }
  }, [navigate]);

  const handleSignup = () => {
    navigate('/signup', { replace: true });
  };
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      rememberMe: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const credentials = {
          username: values.username,
          password: values.password,
        };

        const result = await login(credentials).unwrap();
        const { access } = result;
        localStorage.setItem('access', access);

        if (values.rememberMe) {
          localStorage.setItem('rememberedUsername', values.username);
          localStorage.setItem('rememberMe', 'true');
        } else {
          localStorage.removeItem('rememberedUsername');
          localStorage.removeItem('rememberMe');
        }

        const decodedToken = jose.decodeJwt(result.access);
        if (decodedToken) {
          localStorage.setItem('name', `${decodedToken.first_name} ${decodedToken.last_name}`);
          localStorage.setItem('username', decodedToken.username);
          localStorage.setItem('email', decodedToken.email);
          localStorage.setItem('tempPassChanged', decodedToken.temp_pass_changed ? 'true' : 'false');
        }

        if (!decodedToken.temp_pass_changed) {
          console.log('temp pass changed', decodedToken.temp_pass_changed);
          navigate('/first-login-password', { replace: true });
        } else {
          // navigate to the dashboard page
          navigate('/dashboard', { replace: true });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.status === 401) {
          toast.error('Invalid username or password. Try again.');
        }
        console.error('Error', error);
      }
    },
  });

  const handleRememberMeChange = (event) => {
    formik.setFieldValue('rememberMe', event.target.checked);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            id="username"
            name="username"
            label="Username"
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Stack>
        <Stack mb={3} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              id="rememberMe"
              name="rememberMe"
              color="primary"
              checked={formik.values.rememberMe}
              onChange={handleRememberMeChange}
            />
            <Typography variant="body2">Remember me</Typography>
          </div>
          <Typography
            variant="body2"
            align="right"
            color="#0A9EF2"
            style={{ cursor: 'pointer' }}
            onClick={() => setForgotPassword(true)}
          >
            Forgot password?
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="center">
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            loadingPosition="start"
            startIcon={loading ? <CircularProgress size={20} /> : null}
            sx={{ backgroundColor: '#0A9EF2', padding: 3.5 }}
          >
            Login
          </LoadingButton>
          <Button
            size="large"
            variant="contained"
            sx={{ backgroundColor: '#0A9EF2', padding: 3.5 }}
            onClick={handleSignup}
          >
            Sign Up
          </Button>
        </Stack>
      </form>
    </>
  );
}
