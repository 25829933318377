import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    access: null,
  },
  reducers: {
    setCredentials: (state, { payload: { user, access } }) => {
      state.user = user;
      state.access = access;
    },
  },
  extraReducers: () => {},
});

export const { setCredentials } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
