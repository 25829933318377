/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Grid, Typography, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Iconify from '../components/iconify';
import { getUserGroups } from '../utils';

export default function NewQrCodePage() {
  const [loading, setLoading] = useState(false);
  const [qrCodeGenerated, setQrCodeGenerated] = useState(false);
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const userGroups = getUserGroups();
    if (!userGroups.includes('admin')) {
      navigate('/dashboard', { replace: true });
    }
  });

  const formik = useFormik({
    initialValues: {
      title: '',
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const token = localStorage.getItem('access');
        const response = await axios.post(`${process.env.REACT_APP_BASE_API_LOCAL}/create-qrcodes/`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        });
        // Create a URL for the Blob
        const qrCodeImageUrl = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

        // Store the image URL in the state
        setImageUrl(qrCodeImageUrl);

        setQrCodeGenerated(true);
        toast.success('QR codes successfully created. Select one of the options below.');
        setLoading(false);
      } catch (error) {
        console.error('Error opening QR codes file:', error);
        toast.error('Error creating QR codes. Try again later.');
        setLoading(false);
      }
    },
  });

  const handleQrCodePrint = () => {
    // Open the image URL in a new tab
    window.open(imageUrl, '_blank');
  };

  const handleQrCodeDownload = () => {
    // Download the image response
    const downloadLink = document.createElement('a');
    downloadLink.href = imageUrl;
    downloadLink.download = 'qr_codes.pdf';
    downloadLink.click();
  };
  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | VeriDoc</title>
      </Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              New VeriDoc Codes
            </Typography>
            {!qrCodeGenerated ? (
              <LoadingButton
                type="submit"
                variant="contained"
                onClick={formik.handleSubmit}
                loading={loading}
                loadingPosition="start"
                startIcon={loading ? <CircularProgress size={20} /> : null}
              >
                Create VeriDoc Codes
              </LoadingButton>
            ) : null}
          </Stack>
          {qrCodeGenerated && (
            <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={1}>
              <Button variant="contained" sx={{ marginRight: 1 }} startIcon={<Iconify icon="eva:share-fill" />}>
                Share
              </Button>
              <Button
                variant="contained"
                onClick={handleQrCodePrint}
                sx={{ marginRight: 1 }}
                startIcon={<Iconify icon="eva:download-fill" />}
              >
                Print
              </Button>
              <Button
                variant="contained"
                onClick={handleQrCodeDownload}
                sx={{ marginRight: 1 }}
                startIcon={<Iconify icon="eva:download-fill" />}
              >
                Download
              </Button>

              <Button variant="contained" sx={{ marginRight: 1 }} startIcon={<Iconify icon="eva:email-fill" />}>
                Email
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  navigate('/dashboard', { replace: true });
                }}
                startIcon={<Iconify icon="eva:checkmark-square-2-fill" />}
              >
                Done
              </Button>
            </Stack>
          )}
        </Grid>
      </Grid>
    </>
  );
}
