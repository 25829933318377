/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { LoadingButton } from '@mui/lab';
import { Button, TextField, Stack, Grid, Typography, MenuItem, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import isEmail from 'validator/lib/isEmail';
import { createNewClient } from '../services/api';
import { USA_STATES } from '../utils/conts';
import { LightTooltip } from '../components/custom-components/custom-tooltip';

const validationSchema = yup.object({
  username: yup
    .string('Enter the username')
    .required('Username is required')
    .max(100, `Username can't be more than 100 characters`)
    .matches(/^[a-z]+$/, 'Username must be lowercase and contain no spaces or special characters'),
  practice_name: yup
    .string('Enter the practice name')
    .required('Practice name is required')
    .max(100, `Practice name can't be more than 100 characters`),
  email: yup
    .string('Enter the email')
    .email('Please enter a valid email address')
    .required('Email is required')
    .test(
      'is-valid',
      () => `Please enter a valid email address`,
      (value) => (value ? isEmail(value) : new yup.ValidationError('Please enter a valid email address'))
    )
    .max(100, `Email can't be more than 100 characters`),
  phone: yup
    .number('Enter the phone number')
    .required('Phone number is required')
    .test('maxDigits', 'Phone number should have 10 digits', (value) => {
      if (value) {
        return value.toString().length === 10;
      }
      return true;
    }),
  zipcode: yup
    .number('Enter the zip code')
    .required('Zip code is required')
    .test('maxDigits', 'Zip code should have 5 digits', (value) => {
      if (value) {
        return value.toString().length === 5;
      }
      return true;
    }),
  state: yup.string('Enter the state').required('State is required').max(50, `State can't be more than 50 characters`),
  city: yup.string('Enter the city').required('City is required').max(50, `City can't be more than 50 characters`),
  address: yup
    .string('Enter the address')
    .required('Address is required')
    .max(200, `Address can't be more than 200 characters`),
});

export default function NewClientForm() {
  const [loading, setLoading] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: '',
      practice_name: '',
      email: '',
      phone: '',
      zipcode: '',
      state: '',
      city: '',
      address: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await createNewClient(values);
        setAccountCreated(true);
        toast.success('Client account successfully created.');
        setLoading(false);
        navigate('/clients', { replace: true });
      } catch (error) {
        console.error('Error', error);
        if (error.response.status === 409) {
          toast.error('Username or email already exists. Try with different values.');
        } else {
          toast.error('Error creating client account. Try again later.');
        }
        setLoading(false);
      }
    },
  });
  const handleFieldBlur = (fieldName) => {
    formik.setFieldTouched(fieldName, true, true);
  };

  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | New Client</title>
      </Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <LightTooltip title="Choose a unique username for the client's account.">
                <TextField
                  id="username"
                  name="username"
                  label="Username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={() => handleFieldBlur('username')}
                  error={formik.touched.username && Boolean(formik.errors.username)}
                  helperText={formik.touched.username && formik.errors.username}
                />
              </LightTooltip>
              <TextField
                id="practice_name"
                name="practice_name"
                label="Practice Name"
                value={formik.values.practice_name}
                onChange={formik.handleChange}
                onBlur={() => handleFieldBlur('practice_name')}
                error={formik.touched.practice_name && Boolean(formik.errors.practice_name)}
                helperText={formik.touched.practice_name && formik.errors.practice_name}
              />
              <TextField
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={() => handleFieldBlur('email')}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                id="phone"
                name="phone"
                label="Phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={() => handleFieldBlur('phone')}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
              <TextField
                id="zipcode"
                name="zipcode"
                label="Zip Code"
                value={formik.values.zipcode}
                onChange={formik.handleChange}
                onBlur={() => handleFieldBlur('zipcode')}
                error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
                helperText={formik.touched.zipcode && formik.errors.zipcode}
              />
              <TextField
                id="state"
                name="state"
                label="State"
                select
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={() => handleFieldBlur('state')}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              >
                {USA_STATES.map((state) => (
                  <MenuItem key={state.abbreviation} value={state.abbreviation}>
                    {state.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="city"
                name="city"
                label="City"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={() => handleFieldBlur('city')}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
              <TextField
                id="address"
                name="address"
                label="Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={() => handleFieldBlur('address')}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
              {!accountCreated && (
                <Stack direction="row" alignItems="center" justifyContent="start" mb={5}>
                  <Typography variant="h4" gutterBottom />
                  <LightTooltip title="Finalize the creation of the client's account.">
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={loading}
                      loadingPosition="start"
                      startIcon={loading ? <CircularProgress size={20} /> : null}
                      sx={{ backgroundColor: '#0A9EF2' }}
                    >
                      Create Client Account
                    </LoadingButton>
                  </LightTooltip>
                  <Button
                    variant="outlined"
                    sx={{ marginLeft: '15px' }}
                    style={{ color: '#0A9EF2', borderColor: '#0A9EF2' }}
                    onClick={() => navigate('/clients')}
                  >
                    Cancel
                  </Button>
                </Stack>
              )}
            </Stack>
          </form>
        </Grid>
        <Grid item xs={12} md={2} lg={3} />
      </Grid>
    </>
  );
}
