import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import * as jose from 'jose';
import Header from './header';
import Nav from './nav';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // Check if the access token exists in local storage
    const accessToken = localStorage.getItem('access');
    if (!accessToken) {
      // If the access token is not present, redirect the user to the login page
      console.log('no token');
      navigate('/login', { replace: true });
    } else {
      try {
        const decodedToken = jose.decodeJwt(accessToken);
        if (decodedToken) {
          const currentTimestamp = Math.floor(Date.now() / 1000);
          if (decodedToken.exp < currentTimestamp) {
            console.log('token expired');
            navigate('/login', { replace: true });
          }
        }
      } catch (error) {
        // Error parsing token, handle accordingly
        console.log('invalid token error');
        navigate('/login', { replace: true });
      }
    }
  }, [navigate]);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
