import {
  Box,
  Card,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';

function TabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function Table1() {
  const TABLE_HEAD = [
    { id: 'audit_date', label: 'Date', alignRight: false },
    { id: 'audit_time', label: 'Time', alignRight: false },
    { id: 'practice_name', label: 'Practice Name', alignRight: false },
    { id: '' },
  ];

  return (
    <>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox />
              </TableCell>
              {TABLE_HEAD.map((headCell) => (
                <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox />
              </TableCell>
              <TableCell align="left">03-06-24</TableCell>
              <TableCell align="left">10:24</TableCell>
              <TableCell align="left">Practice Name</TableCell>
              <TableCell align="right">
                <IconButton aria-controls="client-actions-menu" aria-haspopup="true">
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination rowsPerPageOptions={[10, 15, 25]} component="div" />
    </>
  );
}

function Table2() {
  const TABLE_HEAD = [
    { id: 'audit_date', label: 'Date', alignRight: false },
    { id: 'audit_time', label: 'Time', alignRight: false },
    { id: 'username', label: 'Username', alignRight: false },
    { id: 'practice_name', label: 'Practice Name', alignRight: false },
    { id: 'ip_address', label: 'IP Address', alignRight: false },
    { id: '' },
  ];

  return (
    <>
      <FormControl sx={{ width: 500, marginBottom: 3 }}>
        <InputLabel id="demo-simple-select-label">Select User</InputLabel>
        <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Select User">
          <MenuItem>User</MenuItem>
        </Select>
      </FormControl>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox />
              </TableCell>
              {TABLE_HEAD.map((headCell) => (
                <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox />
              </TableCell>
              <TableCell align="left">03-06-24</TableCell>
              <TableCell align="left">10:24</TableCell>
              <TableCell align="left">Adeel</TableCell>
              <TableCell align="left">Practice Name</TableCell>
              <TableCell align="left">123.45.67.89</TableCell>
              <TableCell align="right">
                <IconButton aria-controls="client-actions-menu" aria-haspopup="true">
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination rowsPerPageOptions={[10, 15, 25]} component="div" />
    </>
  );
}

export default function AuditTrail() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | Audit Trail</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Box marginBottom={1}>
            <Typography variant="h4" gutterBottom>
              Audit Trail
            </Typography>
          </Box>

          <Card>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: 3 }}>
              <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="My actions" />
                <Tab label="Client actions" />
              </Tabs>
            </Box>

            <TabPanel value={activeTab} index={0}>
              <Table1 />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <Table2 />
            </TabPanel>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
