import React, { useState } from 'react';
import { Typography, TextField, Button, Stack, Box, Link, FormHelperText, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { resetPassword } from '../../../services/api';

const validationSchema = yup.object({
  username: yup.string().max(255, 'Username should be max 255 characters').required('Username is required'),
});

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [usernameSent, setUsernameSent] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: '',
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      setLoading(true);
      try {
        resetPassword({ username: values.username })
          .then(() => {
            setUsernameSent(true);
            setLoading(false);
          })
          .catch(() => {
            toast.error('Unable to send reset password link. Please try again.');
            setLoading(false);
          });
      } catch (error) {
        helpers.setErrors({ submit: error });
        console.error(error);
        setLoading(false);
      }
    },
  });

  return (
    <>
      <Stack direction={'row'} marginBottom={5} alignItems={'center'} justifyContent={'space-between'}>
        <img src="/assets/transmit-logo.png" alt="login" style={{ width: '104px', height: '27px' }} />
        <img src="/assets/billerbay-logo.png" alt="login" style={{ width: '104px', height: '27px', marginTop: 10 }} />
      </Stack>
      <Typography variant="h5" gutterBottom>
        Forgot Your Password?
      </Typography>
      {usernameSent ? (
        <>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} marginBottom={'20px'}>
            <img src="/assets/icons8-email-sent-64.png" alt="login" style={{ width: '84px', height: '84px' }} />
          </Box>

          <Typography variant="body1" marginBottom={'20px'}>
            Reset password link has been sent to your email. Kindly check it.
          </Typography>
          <Typography variant="body2" align="right">
            <Link href="/login" style={{ color: '#0A9EF2', textDecoration: 'none' }}>
              Go Back to Login Page
            </Link>
          </Typography>
        </>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <TextField
            label="Username"
            type="username"
            fullWidth
            margin="normal"
            id="username"
            name="username"
            value={formik.values.username}
            error={!!(formik.touched.username && formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.submit && (
            <FormHelperText error sx={{ mt: 2 }}>
              {formik.errors.submit}
            </FormHelperText>
          )}
          <Stack direction={'row'} spacing={1} marginTop={'20px'}>
            <LoadingButton
              disabled={formik.isSubmitting}
              variant="contained"
              type="submit"
              sx={{ backgroundColor: '#0A9EF2' }}
              loading={loading}
              loadingPosition="start"
              startIcon={loading ? <CircularProgress size={20} /> : null}
            >
              Reset Password
            </LoadingButton>
            <Link href="/login" style={{ textDecoration: 'none' }}>
              <Button variant="outlined" style={{ color: '#0A9EF2', borderColor: '#0A9EF2' }}>
                Go Back
              </Button>
            </Link>
          </Stack>
        </form>
      )}
    </>
  );
}
