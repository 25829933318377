import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, CircularProgress, Card, CardContent, Box } from '@mui/material';
import { toast } from 'react-toastify';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { useNavigate } from 'react-router-dom';
import { getClientSteps, getDashboardInfo } from '../services/api';
import { getUserGroups } from '../utils';
import { USA_STATES } from '../utils/conts';

export default function DashboardPage() {
  const navigate = useNavigate();
  const [clientSteps, setClientSteps] = useState([]);
  const [clientDetails, setClientDetails] = useState('');
  const [loadingClientData, setLoadingClientData] = useState(false);
  const [loadingStepsData, setLoadingStepsData] = useState(false);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    const userGroups = getUserGroups();
    if (userGroups === null) {
      navigate('/login', { replace: true });
    }
    if (userGroups && userGroups.includes('client')) {
      setLoadingClientData(true);
      setLoadingStepsData(true);
      setIsClient(true);
      getClientSteps()
        .then((response) => {
          const steps = response.data;
          setClientSteps(steps);
        })
        .catch(() => {
          const message = 'Cannot get client steps, try again later.';
          toast.error(message);
        })
        .finally(() => {
          setLoadingStepsData(false);
        });
      getDashboardInfo()
        .then((response) => {
          setClientDetails(response.data.info);
        })
        .catch(() => {
          const message = 'Cannot get client details, try again later.';
          toast.error(message);
        })
        .finally(() => {
          setLoadingClientData(false);
        });
    }
  }, []);

  useEffect(() => {
    if (clientSteps.length > 0) {
      const step = clientSteps.find((step) => step.step.name === 'Sign Agreement' && step.status === 'To Do');
      if (step) {
        localStorage.setItem('practiceInfo', 'false');
      } else {
        localStorage.setItem('practiceInfo', 'true');
      }
    }
  }, [clientSteps]);

  function getStateNameByAbbreviation(abbreviation) {
    const state = USA_STATES.find((state) => state.abbreviation === abbreviation);
    return state ? state.name : null;
  }
  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | Dashboard</title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Dashboard
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Hi, {localStorage.getItem('username')}!
            </Typography>
          </Grid>

          {isClient ? (
            <>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <HandshakeIcon sx={{ mr: 1 }} />
                      Pratice Details
                    </Typography>
                    {loadingClientData ? <CircularProgress size={20} /> : null}
                    {clientDetails && (
                      <>
                        <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <strong>Practice Name:</strong> {clientDetails.practice_name}
                        </Typography>
                        <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <strong>Email:</strong> {clientDetails.email}
                        </Typography>
                        <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <strong>Zipcode:</strong> {clientDetails.zipcode}
                        </Typography>
                        <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <strong>City:</strong> {clientDetails.city}
                        </Typography>
                        <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <strong>State:</strong> {getStateNameByAbbreviation(clientDetails.state)}
                        </Typography>
                        <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <strong>Address:</strong> {clientDetails.address}
                        </Typography>
                        <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <strong>Phone:</strong> {clientDetails.phone}
                        </Typography>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <NotificationsIcon sx={{ mr: 1 }} />
                      Notifications
                    </Typography>
                    {loadingStepsData ? (
                      <CircularProgress size={20} />
                    ) : (
                      clientSteps.map((step) => (
                        <Box key={step.id} sx={{ mb: 1 }}>
                          {step.status !== 'Done' && <Typography variant="body1">{step.step.client_label}</Typography>}
                        </Box>
                      ))
                    )}
                    {!loadingStepsData && clientSteps.every((step) => step.status === 'Done') && (
                      <Typography variant="body1">No new updates.</Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Container>
    </>
  );
}
