import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { LightTooltip } from '../custom-components/custom-tooltip';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  const isPracticeInfoDisabled = localStorage.getItem('practiceInfo') === 'false';

  const navItem = (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'white',
          bgcolor: '#5E69F4',
          fontWeight: 'fontWeightBold',
        },
      }}
      disabled={title === 'Practice Information' && isPracticeInfoDisabled}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </StyledNavItem>
  );

  return (
    <div>
      {title === 'Practice Information' && isPracticeInfoDisabled ? (
        <LightTooltip title="Sign contract first">
          <span>{navItem}</span>
        </LightTooltip>
      ) : (
        navItem
      )}
    </div>
  );
}
