import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import { alpha } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  CircularProgress,
  TextField,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
  Button,
} from '@mui/material';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { getUserGroups } from '../../../utils';

import { editClientInfo, getClientContractDetails, updateClientPassword } from '../../../services/api';
import { USA_STATES } from '../../../utils/conts';
import account from '../../../_mock/account';

// ----------------------------------------------------------------------

const validationSchemaClient = yup.object({
  email: yup
    .string('Enter the email')
    .email('Please enter a valid email address')
    .required('Email is required')
    .max(100, `Email can't be more than 100 characters`),
  state: yup.string('Enter the state').required('State is required').max(200, `State can't be more than 50 characters`),
  city: yup.string('Enter the city').required('City is required').max(50, `City can't be more than 50 characters`),
  zipcode: yup
    .number('Enter the zip code')
    .required('Zip code is required')
    .test('maxDigits', 'Zip code should have 5 digits', (value) => {
      if (value) {
        return value.toString().length === 5;
      }
      return true;
    }),
  address: yup
    .string('Enter the address')
    .required('Address is required')
    .max(200, `Address can't be more than 200 characters`),
  phone: yup
    .string('Enter the phone number')
    .required('Phone number is required')
    .test('maxDigits', 'Phone number should have 10 digits', (value) => {
      if (value) {
        const cleanedValue = value.replace(/\D/g, '');
        return cleanedValue.length === 10;
      }
      return true;
    }),
});

const validationSchemaUpdatePassword = yup.object({
  current_password: yup
    .string('Enter the current password')
    .required('Password is required')
    .max(100, `Password can't be more than 100 characters`),
  new_password: yup
    .string('Enter the new password')
    .required('New password is required')
    .max(100, `New password can't be more than 100 characters`),
  new_password2: yup
    .string('Enter the new password')
    .oneOf([yup.ref('new_password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clientEditAccountModalOpen, setClientEditAccountModalOpen] = useState(false);
  const [clientUpdateAccountPasswordModalOpen, setClientUpdateAccountPasswordModalOpen] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setUserGroups(getUserGroups());
  }, []);

  const handlePopoverOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('access');
    localStorage.removeItem('practiceInfo');
    navigate('/login', { replace: true });
    setOpen(null);
  };

  const formikUpdateAccount = useFormik({
    initialValues: {
      email: '',
      password: '',
      phone: '',
      zipcode: '',
      city: '',
      state: '',
      address: '',
    },
    validationSchema: validationSchemaClient,
    onSubmit: async (values) => {
      setLoading(true);
      await editClientInfo(values)
        .then(() => {
          const message = 'Client info successfully updated.';
          toast.success(message);
        })
        .catch((error) => {
          console.log('error', error);
          toast.error('Error updating client info. Try again later.');
        })
        .finally(() => {
          setLoading(false);
          handleClientEditAccountModalClose();
        });
    },
  });

  const handleFieldBlurUpdateAccount = (fieldName) => {
    formikUpdateAccount.setFieldTouched(fieldName, true, true);
  };

  const handleClientEditAccountModalOpen = async () => {
    await getClientContractDetails()
      .then((response) => {
        const clientInfo = response.data.client_info;
        formikUpdateAccount.setValues(clientInfo);
        console.log('clientInfo', clientInfo);
      })
      .catch((error) => {
        console.log('error', error);
        toast.error('Error getting client info. Try again later.');
      })
      .finally(() => {
        setLoading(false);
      });
    setClientEditAccountModalOpen(true);
  };
  const handleClientEditAccountModalClose = () => {
    setClientEditAccountModalOpen(false);
    setOpen(null);
    formikUpdateAccount.resetForm();
  };

  const formikUpdateAccountPassword = useFormik({
    initialValues: {
      current_password: '',
      new_password: '',
      new_password2: '',
    },
    validationSchema: validationSchemaUpdatePassword,
    onSubmit: async (values) => {
      setLoading(true);
      await updateClientPassword(values)
        .then(() => {
          const message = 'Password successfully updated.';
          toast.success(message);
          handleClientUpdateAccountPasswordModalClose();
        })
        .catch((error) => {
          console.log('error', error);
          if (error.response.status === 401) {
            toast.error('Current password is incorrect.');
          } else {
            toast.error('Error updating password. Try again later.');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleFieldBlurUpdateAccountPassword = (fieldName) => {
    formikUpdateAccountPassword.setFieldTouched(fieldName, true, true);
  };

  const handleClientUpdateAccountPasswordModalOpen = () => {
    setClientUpdateAccountPasswordModalOpen(true);
  };
  const handleClientUpdateAccountPasswordModalClose = () => {
    setClientUpdateAccountPasswordModalOpen(false);
    setOpen(null);
    formikUpdateAccountPassword.resetForm();
  };

  return (
    <>
      <Dialog
        open={clientUpdateAccountPasswordModalOpen}
        onClose={handleClientUpdateAccountPasswordModalClose}
        fullWidth
      >
        <DialogTitle>Update Account Password</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <form onSubmit={formikUpdateAccountPassword.handleSubmit}>
                <Stack spacing={1} paddingTop={1}>
                  <TextField
                    id="current_password"
                    name="current_password"
                    label="Current Password"
                    type="password"
                    value={formikUpdateAccountPassword.values.current_password}
                    onChange={formikUpdateAccountPassword.handleChange}
                    onBlur={() => handleFieldBlurUpdateAccountPassword('current_password')}
                    error={
                      formikUpdateAccountPassword.touched.current_password &&
                      Boolean(formikUpdateAccountPassword.errors.current_password)
                    }
                    helperText={
                      formikUpdateAccountPassword.touched.current_password &&
                      formikUpdateAccountPassword.errors.current_password
                    }
                  />
                  <TextField
                    id="new_password"
                    name="new_password"
                    label="New Password"
                    type="password"
                    value={formikUpdateAccountPassword.values.new_password}
                    onChange={formikUpdateAccountPassword.handleChange}
                    onBlur={() => handleFieldBlurUpdateAccountPassword('new_password')}
                    error={
                      formikUpdateAccountPassword.touched.new_password &&
                      Boolean(formikUpdateAccountPassword.errors.new_password)
                    }
                    helperText={
                      formikUpdateAccountPassword.touched.new_password &&
                      formikUpdateAccountPassword.errors.new_password
                    }
                  />
                  <TextField
                    id="new_password2"
                    name="new_password2"
                    label="Confirm Password"
                    type="password"
                    value={formikUpdateAccountPassword.values.new_password2}
                    onChange={formikUpdateAccountPassword.handleChange}
                    onBlur={() => handleFieldBlurUpdateAccountPassword('new_password2')}
                    error={
                      formikUpdateAccountPassword.touched.new_password2 &&
                      Boolean(formikUpdateAccountPassword.errors.new_password2)
                    }
                    helperText={
                      formikUpdateAccountPassword.touched.new_password2 &&
                      formikUpdateAccountPassword.errors.new_password2
                    }
                  />
                  <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5} spacing="5px">
                    <Button onClick={handleClientUpdateAccountPasswordModalClose} color="primary">
                      Cancel
                    </Button>
                    <Typography variant="h4" gutterBottom />
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={loading}
                      loadingPosition="start"
                      startIcon={loading ? <CircularProgress size={20} /> : null}
                    >
                      Save
                    </LoadingButton>
                  </Stack>
                </Stack>
              </form>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={clientEditAccountModalOpen} onClose={handleClientEditAccountModalClose} fullWidth>
        <DialogTitle>Update Account</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <form onSubmit={formikUpdateAccount.handleSubmit}>
                <Stack spacing={1} paddingTop={1}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={formikUpdateAccount.values.email}
                    onChange={formikUpdateAccount.handleChange}
                    onBlur={() => handleFieldBlurUpdateAccount('email')}
                    error={formikUpdateAccount.touched.email && Boolean(formikUpdateAccount.errors.email)}
                    helperText={formikUpdateAccount.touched.email && formikUpdateAccount.errors.email}
                  />
                  <TextField
                    id="phone"
                    name="phone"
                    label="Phone"
                    value={formikUpdateAccount.values.phone}
                    onChange={formikUpdateAccount.handleChange}
                    onBlur={() => handleFieldBlurUpdateAccount('phone')}
                    error={formikUpdateAccount.touched.phone && Boolean(formikUpdateAccount.errors.phone)}
                    helperText={formikUpdateAccount.touched.phone && formikUpdateAccount.errors.phone}
                  />
                  <TextField
                    id="zipcode"
                    name="zipcode"
                    label="Zip Code"
                    value={formikUpdateAccount.values.zipcode}
                    onChange={formikUpdateAccount.handleChange}
                    onBlur={() => handleFieldBlurUpdateAccount('zipcode')}
                    error={formikUpdateAccount.touched.zipcode && Boolean(formikUpdateAccount.errors.zipcode)}
                    helperText={formikUpdateAccount.touched.zipcode && formikUpdateAccount.errors.zipcode}
                  />
                  <TextField
                    id="state"
                    name="state"
                    label="State"
                    select
                    value={formikUpdateAccount.values.state}
                    onChange={formikUpdateAccount.handleChange}
                    onBlur={() => handleFieldBlurUpdateAccount('state')}
                    error={formikUpdateAccount.touched.state && Boolean(formikUpdateAccount.errors.state)}
                    helperText={formikUpdateAccount.touched.state && formikUpdateAccount.errors.state}
                  >
                    {USA_STATES.map((state) => (
                      <MenuItem key={state.abbreviation} value={state.abbreviation}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    id="city"
                    name="city"
                    label="City"
                    value={formikUpdateAccount.values.city}
                    onChange={formikUpdateAccount.handleChange}
                    onBlur={() => handleFieldBlurUpdateAccount('city')}
                    error={formikUpdateAccount.touched.city && Boolean(formikUpdateAccount.errors.city)}
                    helperText={formikUpdateAccount.touched.city && formikUpdateAccount.errors.city}
                  />
                  <TextField
                    id="address"
                    name="address"
                    label="Address"
                    value={formikUpdateAccount.values.address}
                    onChange={formikUpdateAccount.handleChange}
                    onBlur={() => handleFieldBlurUpdateAccount('address')}
                    error={formikUpdateAccount.touched.address && Boolean(formikUpdateAccount.errors.address)}
                    helperText={formikUpdateAccount.touched.address && formikUpdateAccount.errors.address}
                  />
                  <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5} spacing="5px">
                    <Button onClick={handleClientEditAccountModalClose} color="primary">
                      Cancel
                    </Button>
                    <Typography variant="h4" gutterBottom />
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={loading}
                      loadingPosition="start"
                      startIcon={loading ? <CircularProgress size={20} /> : null}
                    >
                      Save
                    </LoadingButton>
                  </Stack>
                </Stack>
              </form>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <IconButton
        onClick={handlePopoverOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {localStorage.getItem('name') || ''}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {localStorage.getItem('username') || ''}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleClientUpdateAccountPasswordModalOpen} sx={{ m: 1 }}>
          Change Password
        </MenuItem>
        {userGroups.includes('client') ? (
          <MenuItem onClick={handleClientEditAccountModalOpen} sx={{ m: 1 }}>
            Edit Profile
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
