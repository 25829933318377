import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Stack,
  Box,
  Link,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { resetPasswordComplete } from '../services/api';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#F4F8FF',
  },
};

const validationSchema = yup.object({
  password: yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export default function NewPasswordPage() {
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const { uid, token } = useParams();

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      setLoading(true);
      try {
        await resetPasswordComplete(uid, token, values.password);
        setPasswordChanged(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        helpers.setErrors({ submit: error });
        console.error(error);
        toast.error(error);
      }
    },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#F4F8FF',
      }}
    >
      <Card>
        <CardContent>
          <Stack direction={'row'} marginBottom={5} alignItems={'center'} justifyContent={'space-between'}>
            <img src="/assets/transmit-logo.png" alt="login" style={{ width: '104px', height: '27px' }} />
            <img
              src="/assets/billerbay-logo.png"
              alt="login"
              style={{ width: '104px', height: '27px', marginTop: 10 }}
            />
          </Stack>
          <Typography variant="h5" gutterBottom>
            Reset Your Password
          </Typography>
          {passwordChanged ? (
            <>
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'} marginBottom={'20px'}>
                <img src="/assets/green-check.png" alt="login" style={{ width: '84px', height: '84px' }} />
              </Box>

              <Typography variant="body1" marginBottom={'20px'}>
                Your password has been reset successfully.
              </Typography>
              <Typography variant="body2" align="right">
                <Link href="/login" style={{ color: '#0A9EF2', textDecoration: 'none' }}>
                  Go Back to Login Page
                </Link>
              </Typography>
            </>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <TextField
                label="New Password"
                type="password"
                fullWidth
                margin="normal"
                id="password"
                name="password"
                value={formik.values.password}
                error={!!(formik.touched.password && formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <TextField
                label="Confirm Password"
                type="password"
                fullWidth
                margin="normal"
                id="confirmPassword"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                error={!!(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.errors.submit && (
                <FormHelperText error sx={{ mt: 2 }}>
                  {formik.errors.submit}
                </FormHelperText>
              )}
              <Box marginTop={'20px'}>
                <LoadingButton
                  disabled={formik.isSubmitting}
                  variant="contained"
                  type="submit"
                  sx={{ backgroundColor: '#0A9EF2' }}
                  loading={loading}
                  loadingPosition="start"
                  startIcon={loading ? <CircularProgress size={20} /> : null}
                >
                  Reset Password
                </LoadingButton>
              </Box>
            </form>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
