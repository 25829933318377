/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  TextField,
  Stack,
  Grid,
  Typography,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  IconButton,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Iconify from '../components/iconify';
import { getUserGroups } from '../utils';
import { LightTooltip } from '../components/custom-components/custom-tooltip';
import { getClients } from '../services/api';

const validationSchema = yup.object({
  client_id: yup
    .string('Select a client')
    .required('Client is required')
    .max(100, `Client can't be more than 100 characters`),
  percentage_terms: yup
    .number('Enter the percentage term')
    .required('Percentage term is required')
    .min(0, `Percentage term can't be less than 0`)
    .max(99, `Percentage term can't be greater than 99`),
  services: yup.array().min(1, 'Please select at least one service').of(yup.string(), 'Invalid service option'),
  individuals_included: yup
    .boolean()
    .test('individuals_included', 'Please check at least one option', function (value) {
      const entityIncluded = this.resolve(yup.ref('entity_included'));
      return value || entityIncluded;
    })
    .nullable()
    .default(false),
  covered_individuals: yup
    .array()
    .test('individuals', 'Please add at least one individual', function (value) {
      const individualsIncluded = this.resolve(yup.ref('individuals_included'));
      if (individualsIncluded) {
        return value && value.length > 0;
      }
      return true;
    })
    .of(yup.string().nullable()),
  activation_date: yup.date('Enter the activation date').nullable().required('Activation date is required'),
  include_associate_name: yup.boolean().default(false),
  associate_name: yup.string().test('associate_name', 'Please enter entity name', function (value) {
    const associateIncluded = this.resolve(yup.ref('include_associate_name'));
    if (associateIncluded) {
      return value !== undefined;
    }
    return true;
  }),
});

export default function NewClientContractPage() {
  const [loading, setLoading] = useState(false);
  const [contractGenerated, setContractGenerated] = useState(false);
  const navigate = useNavigate();
  const [pdfUrl, setPdfUrl] = useState('');
  const [clients, setClients] = useState([]);

  const isAdmin = () => {
    const userGroups = getUserGroups();
    return userGroups.includes('admin');
  };

  useEffect(() => {
    const userGroups = getUserGroups();
    if (!userGroups.includes('admin') && !userGroups.includes('associate')) {
      navigate('/dashboard', { replace: true });
    } else {
      getClients()
        .then((clients) => {
          setClients(clients.data);
        })
        .catch(() => {
          const message = 'Cannot get clients, try again later.';
          toast.error(message);
        });
    }
  }, []);
  const formik = useFormik({
    initialValues: {
      percentage_terms: 3.5,
      services: [],
      entity_included: false,
      entity_name: '',
      individuals_included: true,
      covered_individuals: [],
      activation_date: null,
      include_associate_name: false,
      associate_name: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        values.contract_activation_date = format(values.activation_date, 'yyyy-MM-dd');
        const token = localStorage.getItem('access');
        const response = await axios.post(`${process.env.REACT_APP_BASE_API_LOCAL}/contracts/`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const url = response.data.download_url;
        setPdfUrl(url);
        setContractGenerated(true);
        toast.success('PDF successfully created. Select one of the options below.');
        setLoading(false);
      } catch (error) {
        console.error('Error opening PDF:', error);
        toast.error('Error creating contract. Try again later.');
        setLoading(false);
      }
    },
  });
  const handleFieldBlur = (fieldName) => {
    formik.setFieldTouched(fieldName, true, true);
  };

  const handlePDFPrint = () => {
    window.open(pdfUrl, '_blank');
  };

  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | New Contract</title>
      </Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12} md={2} lg={3} />
        <Grid item xs={12} md={8} lg={6}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <IconButton onClick={() => navigate('/clients')}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4">New Contract</Typography>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <TextField
                id="client_id"
                name="client_id"
                label="Client"
                select
                value={formik.values.client_id}
                onChange={formik.handleChange}
                onBlur={() => handleFieldBlur('client_id')}
                error={formik.touched.client_id && Boolean(formik.errors.client_id)}
                helperText={formik.touched.client_id && formik.errors.client_id}
              >
                {clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.practice_name}
                  </MenuItem>
                ))}
              </TextField>
              <LightTooltip title="Define the percentage of revenue allocated for services rendered.">
                <TextField
                  id="percentage_terms"
                  name="percentage_terms"
                  label="Percentage Terms"
                  value={formik.values.percentage_terms}
                  onChange={formik.handleChange}
                  onBlur={() => handleFieldBlur('percentage_terms')}
                  error={formik.touched.percentage_terms && Boolean(formik.errors.percentage_terms)}
                  helperText={formik.touched.percentage_terms && formik.errors.percentage_terms}
                  InputProps={{
                    endAdornment: (
                      <>
                        <span>%</span>
                        <IconButton
                          onClick={() => {
                            const value =
                              formik.values.percentage_terms === ''
                                ? 0
                                : parseFloat(formik.values.percentage_terms, 10);
                            formik.setFieldValue('percentage_terms', (value - 0.1).toFixed(1));
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            const value =
                              formik.values.percentage_terms === ''
                                ? 0
                                : parseFloat(formik.values.percentage_terms, 10);
                            formik.setFieldValue('percentage_terms', (value + 0.1).toFixed(1));
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </>
                    ),
                  }}
                />
              </LightTooltip>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="individuals_included"
                      name="individuals_included"
                      checked={formik.values.individuals_included}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Individual(s) Included"
                />
              </FormControl>
              {formik.values.individuals_included && (
                <>
                  <FormControl>
                    {formik.values.covered_individuals.map((individual, index) => (
                      <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                        <TextField
                          name={`covered_individuals[${index}]`}
                          label="Individual"
                          fullWidth
                          value={individual}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.covered_individuals && Boolean(formik.errors.covered_individuals?.[index])
                          }
                          helperText={formik.touched.covered_individuals && formik.errors.covered_individuals?.[index]}
                        />
                        <IconButton
                          onClick={() => {
                            const updatedCoveredIndividuals = formik.values.covered_individuals.filter(
                              (_, i) => i !== index
                            );
                            formik.setFieldValue('covered_individuals', updatedCoveredIndividuals);
                          }}
                          aria-label="delete-individual"
                        >
                          <RemoveIcon />
                        </IconButton>
                      </div>
                    ))}
                    <Button
                      variant="outlined"
                      style={{ height: '50px', width: '190px', color: '#0A9EF2', borderColor: '#0A9EF2' }}
                      onClick={() =>
                        formik.setFieldValue('covered_individuals', [...formik.values.covered_individuals, ''])
                      }
                    >
                      Add Individual
                    </Button>
                    {formik.touched.covered_individuals && formik.errors.covered_individuals && (
                      <FormHelperText error>{formik.errors.covered_individuals}</FormHelperText>
                    )}
                  </FormControl>
                </>
              )}
              <LightTooltip title="Select the services to be included in this contract.">
                <InputLabel id="services-label">Services</InputLabel>
              </LightTooltip>
              <FormControl>
                <MenuItem
                  value="Billing Audit"
                  disabled={formik.values.services.some((service) =>
                    [
                      'Revenue Cycle Management',
                      'Credentialing',
                      'MACRA & MIPS Consulting',
                      'Digital Marketing',
                      'Practice Management',
                    ].includes(service)
                  )}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="services"
                        value="Billing Audit"
                        checked={formik.values.services.includes('Billing Audit')}
                        onChange={formik.handleChange}
                        onBlur={() => handleFieldBlur('services')}
                        disabled={formik.values.services.some((service) =>
                          [
                            'Revenue Cycle Management',
                            'Credentialing',
                            'MACRA & MIPS Consulting',
                            'Digital Marketing',
                            'Practice Management',
                          ].includes(service)
                        )}
                      />
                    }
                    label="Billing Audit"
                  />
                </MenuItem>
                <MenuItem value="Revenue Cycle Management" disabled={formik.values.services.includes('Billing Audit')}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="services"
                        value="Revenue Cycle Management"
                        checked={formik.values.services.includes('Revenue Cycle Management')}
                        onChange={formik.handleChange}
                        onBlur={() => handleFieldBlur('services')}
                        disabled={formik.values.services.includes('Billing Audit')}
                      />
                    }
                    label="Revenue Cycle Management"
                  />
                </MenuItem>
                <MenuItem value="Credentialing" disabled={formik.values.services.includes('Billing Audit')}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="services"
                        value="Credentialing"
                        checked={formik.values.services.includes('Credentialing')}
                        onChange={formik.handleChange}
                        onBlur={() => handleFieldBlur('services')}
                        disabled={formik.values.services.includes('Billing Audit')}
                      />
                    }
                    label="Credentialing"
                  />
                </MenuItem>
                <MenuItem value="MACRA & MIPS Consulting" disabled={formik.values.services.includes('Billing Audit')}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="services"
                        value="MACRA & MIPS Consulting"
                        checked={formik.values.services.includes('MACRA & MIPS Consulting')}
                        onChange={formik.handleChange}
                        onBlur={() => handleFieldBlur('services')}
                        disabled={formik.values.services.includes('Billing Audit')}
                      />
                    }
                    label="MACRA & MIPS Consulting"
                  />
                </MenuItem>
                <MenuItem value="Digital Marketing" disabled={formik.values.services.includes('Billing Audit')}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="services"
                        value="Digital Marketing"
                        checked={formik.values.services.includes('Digital Marketing')}
                        onChange={formik.handleChange}
                        onBlur={() => handleFieldBlur('services')}
                        disabled={formik.values.services.includes('Billing Audit')}
                      />
                    }
                    label="Digital Marketing"
                  />
                </MenuItem>
                <MenuItem value="Practice Management" disabled={formik.values.services.includes('Billing Audit')}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="services"
                        value="Practice Management"
                        checked={formik.values.services.includes('Practice Management')}
                        onChange={formik.handleChange}
                        onBlur={() => handleFieldBlur('services')}
                        disabled={formik.values.services.includes('Billing Audit')}
                      />
                    }
                    label="Practice Management"
                  />
                </MenuItem>
                {formik.touched.services && formik.errors.services && (
                  <FormHelperText error>{formik.errors.services}</FormHelperText>
                )}
              </FormControl>
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      id="activation_date"
                      name="activation_date"
                      label="Activation Date"
                      value={formik.values.activation_date}
                      onChange={(value) => {
                        formik.setFieldValue('activation_date', value);
                      }}
                      onBlur={() => handleFieldBlur('activation_date')}
                      error={formik.touched.activation_date && Boolean(formik.errors.activation_date)}
                      helperText={formik.touched.activation_date && formik.errors.activation_date}
                      sx={{ width: '100%' }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {formik.touched.activation_date && formik.errors.activation_date && (
                  <FormHelperText error>{formik.errors.activation_date}</FormHelperText>
                )}
              </FormControl>
              {isAdmin() ? (
                <FormControl>
                  <LightTooltip title="Include additional associate to the contract.">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="include_associate_name"
                          name="include_associate_name"
                          checked={formik.values.include_associate_name}
                          onChange={formik.handleChange}
                          disabled
                        />
                      }
                      label="Include Associate Name"
                    />
                  </LightTooltip>
                </FormControl>
              ) : null}
              {formik.values.include_associate_name && (
                <FormControl>
                  <TextField
                    id="associate_name"
                    name="associate_name"
                    label="Associate Name"
                    value={formik.values.associate_name}
                    onChange={formik.handleChange}
                    onBlur={() => handleFieldBlur('associate_name')}
                    error={formik.touched.associate_name && Boolean(formik.errors.associate_name)}
                    helperText={formik.touched.associate_name && formik.errors.associate_name}
                  />
                </FormControl>
              )}
              {!contractGenerated && (
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="start" mb={5}>
                  <LightTooltip title="Click to finalize and create the new contract.">
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      sx={{ backgroundColor: '#0A9EF2' }}
                      loading={loading}
                      loadingPosition="start"
                      startIcon={loading ? <CircularProgress size={20} /> : null}
                    >
                      Create Contract
                    </LoadingButton>
                  </LightTooltip>
                  <Button
                    variant="outlined"
                    style={{ color: '#0A9EF2', borderColor: '#0A9EF2' }}
                    onClick={() => navigate('/contracts')}
                  >
                    Cancel
                  </Button>
                </Stack>
              )}
              {contractGenerated && (
                <>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Button variant="contained" startIcon={<Iconify icon="eva:share-fill" />}>
                      Share
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handlePDFPrint}
                      startIcon={<Iconify icon="eva:download-fill" />}
                    >
                      Print/Download
                    </Button>
                    <Button variant="contained" startIcon={<Iconify icon="eva:email-fill" />}>
                      Email
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigate('/contracts', { replace: true });
                      }}
                      startIcon={<Iconify icon="eva:checkmark-square-2-fill" />}
                    >
                      Done
                    </Button>
                  </Stack>
                </>
              )}
            </Stack>
          </form>
        </Grid>
        <Grid item xs={12} md={2} lg={3} />
      </Grid>
    </>
  );
}
