import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { useState, useEffect } from 'react';
import { DateRangeIcon } from '@mui/x-date-pickers';
import ReplayIcon from '@mui/icons-material/Replay';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { getClaimsDashboardInfo, getClaimsDashboardFilterValues } from '../services/api';
import BasicDatePicker from '../components/custom-components/custom-date-picker';

const claimsDashboardData = {
  sum: {
    total_balance: 0,
    total_deductible: 0,
    total_patient_payments: 0,
    total_insurance_payments: 0,
    total_allowed_amount: 0,
    total_charged_amount: 0,
    total_claims: 0,
  },
  count: {
    insurance_type_count: [
      {
        insurance_type: 'WC / MVA',
        claim_count: 0,
      },
      {
        insurance_type: 'NO CHARGE',
        claim_count: 0,
      },
      {
        insurance_type: 'Tricare',
        claim_count: 0,
      },
      {
        insurance_type: 'Commercial Insurances',
        claim_count: 0,
      },
      {
        insurance_type: 'TRICARE',
        claim_count: 0,
      },
      {
        insurance_type: 'Government Insurances',
        claim_count: 0,
      },
      {
        insurance_type: 'SELF PAY',
        claim_count: 0,
      },
    ],
    claim_status_count: [
      {
        claim_status: 'CLAIM AT INSURANCE',
        claim_count: 0,
      },
      {
        claim_status: 'ACTION REQUIRED',
        claim_count: 0,
      },
      {
        claim_status: 'BALANCE DUE PATIENT',
        claim_count: 0,
      },
      {
        claim_status: 'MEDIATION APPLIED',
        claim_count: 0,
      },
      {
        claim_status: 'PROBLEM AT INSURANCE',
        claim_count: 0,
      },
      {
        claim_status: 'PAID',
        claim_count: 0,
      },
      {
        claim_status: 'COPAY',
        claim_count: 0,
      },
      {
        claim_status: 'REJECTED AT INSURANCE',
        claim_count: 0,
      },
      {
        claim_status: 'NSA NEGOTIATED',
        claim_count: 0,
      },
      {
        claim_status: 'CLAIM PROCESSED UNDER NSA',
        claim_count: 0,
      },
      {
        claim_status: 'ON HOLD',
        claim_count: 0,
      },
      {
        claim_status: 'DEDUCTIBLE',
        claim_count: 0,
      },
      {
        claim_status: 'WAITING FOR REVIEW',
        claim_count: 0,
      },
      {
        claim_status: 'CO-INSURANCE',
        claim_count: 0,
      },
      {
        claim_status: 'USER PRINT & MAIL TO INSURANCE',
        claim_count: 0,
      },
      {
        claim_status: 'MEDIATION PROCESSED',
        claim_count: 0,
      },
      {
        claim_status: 'APPEAL AT INSURANCE',
        claim_count: 0,
      },
      {
        claim_status: 'NEGOTIATED CLAIM',
        claim_count: 0,
      },
    ],
    total_claims: 0,
  },
};

export default function DmrDashboard() {
  const [claimsDashboardInfo, setClaimsDashboardInfo] = useState(claimsDashboardData);
  const [allInsuranceTypes, setAllInsuranceTypes] = useState([]);
  const [allClaimsStatus, setAllClaimsStatus] = useState([]);
  const [allClaimPayers, setAllClaimPayers] = useState([]);
  const [selectedObservationClaim, setSelectedObservationClaim] = useState('');
  const [selectedCovid, setSelectedCovid] = useState('');
  const [selectedClaimStatus, setSelectedClaimStatus] = useState('');
  const [selectedInsuranceType, setSelectedInsuranceType] = useState('');
  const [selectedClaimPrimaryPayer, setSelectedClaimPrimaryPayer] = useState('');
  const [date, setDate] = useState({
    startValue: null,
    endValue: null,
  });
  const [selectedDateTextValue, setSelectedDateTextValue] = useState('');
  const [dateModalOpen, setDateModal] = useState({
    start: false,
    end: false,
  });

  const getDashboardInfo = async () => {
    try {
      const response = await getClaimsDashboardInfo(
        date.startValue,
        date.endValue,
        selectedInsuranceType,
        selectedObservationClaim,
        selectedClaimPrimaryPayer,
        selectedCovid,
        selectedClaimStatus
      );
      setClaimsDashboardInfo(response?.data?.info);
    } catch (error) {
      toast.error('Something went wrong');
      console.log(error);
    }
  };

  const getFilterValues = async () => {
    try {
      const response = await getClaimsDashboardFilterValues();

      setAllInsuranceTypes(response?.data?.info?.insurance_types);
      setAllClaimsStatus(response?.data?.info?.claim_statuses);
      setAllClaimPayers(response?.data?.info?.claim_primary_payers);
    } catch (error) {
      toast.error('Something went wrong');
      console.log(error);
    }
  };
  useEffect(() => {
    getFilterValues();
  }, []);

  useEffect(() => {
    getDashboardInfo();
  }, [
    date.startValue,
    date.endValue,
    selectedInsuranceType,
    selectedObservationClaim,
    selectedCovid,
    selectedClaimStatus,
    selectedClaimPrimaryPayer,
  ]);

  const cardsData = [
    {
      id: 1,
      title: 'Total Number of Claims',
      description: claimsDashboardInfo?.count?.total_claims || 0,
      icon: <AccessibleForwardIcon />,
    },
    {
      id: 2,
      title: 'Total Charge Amount',
      description: `$${claimsDashboardInfo?.sum?.total_balance}` || '$0',
      icon: <TrendingUpIcon />,
    },
    {
      id: 3,
      title: 'Total Insurance Payments',
      description: `$${claimsDashboardInfo?.sum?.total_insurance_payments}` || '$0',
      icon: <AttachMoneyIcon />,
    },
    { id: 4, title: 'Total Patient Resp.', description: '$0', icon: <AccountBalanceWalletIcon /> },
    {
      id: 5,
      title: 'Total Patient Payments',
      description: `$${claimsDashboardInfo?.sum?.total_patient_payments}` || '$0',
      icon: <LocalAtmIcon />,
    },
  ];

  const handleClose = () => {
    setDateModal({
      start: false,
      end: false,
    });
    setSelectedDateTextValue('');
    setDate({
      startValue: null,
      endValue: null,
    });
  };

  const handleSetDate = (value, dateNumber) => {
    if (dateNumber === 'start') {
      setSelectedDateTextValue('');
      setDate({
        startValue: dayjs(value).format('YYYY-MM-DD'),
        endValue: null,
      });
      setDateModal({
        start: false,
        end: true,
      });
      setSelectedDateTextValue(`${dayjs(value).format('DD MMM')} - `);
    } else {
      setDate((d) => ({
        ...d,
        endValue: dayjs(value).format('YYYY-MM-DD'),
      }));
      setDateModal({
        start: false,
        end: false,
      });
      setSelectedDateTextValue(`${selectedDateTextValue}${dayjs(value).format('DD MMM')}`);
    }
  };

  const handleOpen = () => {
    setSelectedDateTextValue('');
    setDateModal({
      start: true,
      end: false,
    });
  };

  const handleObservationClaim = (event) => {
    setSelectedObservationClaim(event.target.value);
  };

  const handleCovidChange = (event) => {
    setSelectedCovid(event.target.value);
  };

  const handleClaimStatusChange = (event) => {
    setSelectedClaimStatus(event.target.value);
  };

  const handleInsuranceTypeChange = (event) => {
    setSelectedInsuranceType(event.target.value);
  };

  const handlePayerNameChange = (event) => {
    setSelectedClaimPrimaryPayer(event.target.value);
  };

  const handleReset = () => {
    setSelectedObservationClaim('');
    setSelectedCovid('');
    setSelectedClaimStatus('');
    setSelectedInsuranceType('');
    setSelectedClaimPrimaryPayer('');
    setSelectedDateTextValue('');
    setDate({
      startValue: null,
      endValue: null,
    });
  };

  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | DMR Dashboard</title>
      </Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Box mb={2}>
            <Typography variant="h4" gutterBottom>
              DMR Dashboard
            </Typography>
          </Box>

          <Stack direction={'row'} alignItems={'center'} gap={6} mb={2} paddingX={2}>
            <Box display="flex" sx={{ width: '100%' }} gap={4} alignItems={'center'}>
              <Box>
                <TextField
                  onClick={handleOpen}
                  value={selectedDateTextValue}
                  variant="outlined"
                  label="Select Date"
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleOpen}>
                          <DateRangeIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <BasicDatePicker
                  open={dateModalOpen.start || dateModalOpen.end}
                  onClose={handleClose}
                  value={date}
                  setValue={handleSetDate}
                  type={dateModalOpen.start ? 'start' : dateModalOpen.end ? 'end' : null}
                />
              </Box>

              <Box>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Observation Claim</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={selectedObservationClaim}
                    onChange={handleObservationClaim}
                  >
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Covid-19</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={selectedCovid}
                    onChange={handleCovidChange}
                  >
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box minWidth={'180px'}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Claim Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedClaimStatus}
                    label="Claim Status"
                    onChange={handleClaimStatusChange}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                  >
                    {allClaimsStatus.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box minWidth={'180px'}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Insurance Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedInsuranceType}
                    label="Insurance Type"
                    onChange={handleInsuranceTypeChange}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                  >
                    {allInsuranceTypes.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box minWidth={'180px'}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Payer Name</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedClaimPrimaryPayer}
                    label="Prctice Name"
                    onChange={handlePayerNameChange}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                  >
                    {allClaimPayers.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Button variant="text" startIcon={<ReplayIcon />} onClick={handleReset}>
                Reset
              </Button>
            </Box>
          </Stack>
          <Grid container spacing={2}>
            {cardsData.map((card) => (
              <Grid item xs={12} sm={6} md={4} key={card.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h5">{card.title}</Typography>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      {card.icon}
                      <Typography variant="body1">{card.description}</Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Claim Status
                  </Typography>
                  <TableContainer style={{ maxHeight: 300, overflowY: 'auto' }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Claim Status</TableCell>
                          <TableCell>Claim Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {claimsDashboardInfo?.count?.claim_status_count?.length > 0 ? (
                          claimsDashboardInfo.count.claim_status_count.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{row?.claim_status}</TableCell>
                              <TableCell>{row?.claim_count}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2}>No data available</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Practice Details
                  </Typography>
                  <TableContainer style={{ maxHeight: 300, overflowY: 'auto' }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Practice Name</TableCell>
                          <TableCell>Claim Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {claimsDashboardInfo?.count?.claim_primary_payer_name_count?.length > 0 ? (
                          claimsDashboardInfo.count.claim_primary_payer_name_count.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{row?.payer_name}</TableCell>
                              <TableCell>{row?.claim_count}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <>
                            <TableRow>
                              <TableCell colSpan={2}>No data available</TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Insurance Type
                  </Typography>
                  <TableContainer style={{ maxHeight: 300, overflowY: 'auto' }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Insurance Type</TableCell>
                          <TableCell>Claim Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {claimsDashboardInfo?.count?.insurance_type_count?.length > 0 ? (
                          claimsDashboardInfo.count.insurance_type_count.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{row?.insurance_type}</TableCell>
                              <TableCell>{row?.claim_count}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <>
                            <TableRow>
                              <TableCell colSpan={2}>No data available</TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
