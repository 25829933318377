import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  TextField,
  Stack,
  Typography,
  Card,
  Button,
  Divider,
  Avatar,
  Box,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { getSupportTicket, createSupportTicketMessage, getSupportTicketMessageFile } from '../services/api';

const validationSchema = yup.object({
  body: yup.string().required('description is required'),
  file: yup.mixed(),
});

function formatDate(createdAt) {
  const date = new Date(createdAt);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'GMT',
    timeZoneName: 'short',
  };
  return new Intl.DateTimeFormat('en-GB', options).format(date);
}

export default function SupportTicketReply() {
  const [supportTicketMessages, setSupportTicketMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    if (id !== undefined) {
      getSupportTicket({ id })
        .then((response) => {
          setSupportTicketMessages(response.data.ticket.messages);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log('Error', error);
          toast.error('Failed to get support tickets.');
        });
    }
  }, [setSupportTicketMessages, id]);

  const formik = useFormik({
    initialValues: {
      body: '',
      file: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const formData = { body: values.body, file: values.file, ticket: id };
      createSupportTicketMessage(formData)
        .then(() => {
          setLoading(false);
          navigate('/support-tickets');
          toast.success('Support ticket message created successfully');
        })
        .catch((error) => {
          setLoading(false);
          console.log('Error', error);
          toast.error('Failed to create support ticket message.');
        });
    },
  });

  const handleFieldBlur = (fieldName) => {
    formik.setFieldTouched(fieldName, true, true);
  };

  const formikSetFile = (event) => {
    // set it to accommodate multiple files
    // check if file is less than 5MB
    console.log('file size', event.currentTarget.files[0].size);
    console.log('file type', event.currentTarget.files[0].type);
    if (event.currentTarget.files[0].size > 5000000) {
      toast.error('File size should be less than 5MB');
    }
    // check if file is of type pdf, png, jpg, jpeg, txt, csv and doc
    else if (
      ![
        'application/pdf',
        'image/png',
        'image/jpg',
        'image/jpeg',
        'text/plain',
        'text/csv',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ].includes(event.currentTarget.files[0].type)
    ) {
      toast.error('File type should be pdf, png, jpg, jpeg, txt, csv or doc');
    } else {
      formik.setFieldValue('file', event.currentTarget.files[0]);
    }
    // formik.setFieldValue('file', event.currentTarget.files);
  };

  const handleDownloadFile = (file) => {
    console.log('file', file);
    const { id } = file;
    getSupportTicketMessageFile({ id })
      .then((response) => {
        window.open(response.data.file.url, '_blank');
      })
      .catch((error) => {
        console.log('Error', error);
        toast.error('Failed to download file.');
      });
  };

  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | Support Ticket</title>
      </Helmet>

      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <IconButton onClick={() => navigate('/support-tickets')}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">Reply</Typography>
      </div>
      <Card sx={{ maxWidth: 750, padding: 5 }}>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <TextField
              id="body"
              name="body"
              label="Message"
              multiline
              rows={4}
              value={formik.values.body}
              onChange={formik.handleChange}
              onBlur={() => handleFieldBlur('body')}
              error={formik.touched.body && Boolean(formik.errors.body)}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                type="file"
                id="fileUpload"
                name="fileUpload"
                onChange={formikSetFile}
                label="Attachments"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                fullWidth
              />
            </div>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="start" spacing={1} mb={5} mt={3}>
            <Typography variant="h4" gutterBottom />
            <LoadingButton
              sx={{
                backgroundColor: '#0A9EF2',
                minWidth: '100px',
              }}
              type="submit"
              variant="contained"
              loading={loading ? 'submit' : false}
              loadingPosition="start"
              startIcon={
                loading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CircularProgress size={20} sx={{ marginRight: 0.5 }} />
                  </Box>
                ) : null
              }
            >
              Submit
            </LoadingButton>
            <Button
              variant="outlined"
              sx={{ color: '#0A9EF2', borderColor: '#0A9EF2', minWidth: '100px' }}
              onClick={() => navigate('/support-tickets')}
            >
              Cancel
            </Button>
          </Stack>
        </form>
        {/* <Divider /> */}
        <Stack spacing={1} marginTop={2}>
          {loading ? (
            <Stack alignItems="center" justifyContent="center" height={200}>
              <CircularProgress />
            </Stack>
          ) : supportTicketMessages.length !== 0 ? (
            supportTicketMessages.map((message, index) => (
              <div key={index}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                  <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    {/* temp, write the first initial of created by below */}
                    <Avatar>A</Avatar>
                    <Typography variant="body1" fontWeight={600}>
                      {message.created_by.username}
                    </Typography>
                  </Stack>
                  <Typography variant="body2">{formatDate(message.created_at)}</Typography>
                </Stack>

                <Typography variant="body1" marginTop={3} marginBottom={2}>
                  {message.body}
                </Typography>
                {message.files && (
                  <div>
                    {message.files.map((file) => (
                      // make a beautiful list with download button
                      <Button key={file.id} onClick={() => handleDownloadFile(file)}>
                        {file.name}
                      </Button>
                    ))}
                  </div>
                )}

                {message.document && (
                  <div>
                    {message.document.type === 'pdf' && (
                      <embed src={message.document.url} type="application/pdf" width="100%" height="400px" />
                    )}
                    {message.document.type === 'image' && (
                      <img src={message.document.url} alt="document" style={{ maxWidth: '100%', height: 'auto' }} />
                    )}
                    {/* Add more document types as needed */}
                  </div>
                )}
                <Divider />
              </div>
            ))
          ) : (
            <Typography variant="body1">No messages found.</Typography>
          )}
        </Stack>
      </Card>
    </>
  );
}
