/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
// @mui
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TableHead,
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  CircularProgress,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Box,
  Menu,
  IconButton,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import * as yup from 'yup';
// components
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import isEmail from 'validator/lib/isEmail';
import { USA_STATES } from '../utils/conts';
import { getUserGroups } from '../utils';
import {
  getClients,
  getClientAccessCredentials,
  getClientContracts,
  getClientContractFile,
  getClientW9Copy,
  lockClientAccount,
  unlockClientAccount,
  editClientInfo,
  updateClientPasswordAdmin,
} from '../services/api';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { LightTooltip } from '../components/custom-components/custom-tooltip';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'Practice Name', label: 'Practice Name', alignRight: false },
  { id: 'Username', label: 'Username', alignRight: false },
  { id: 'Email', label: 'Email', alignRight: false },
  { id: 'Phone', label: 'Phone', alignRight: false },
  { id: 'Status', label: 'Status', alignRight: false },
  { id: 'Steps', label: 'Steps', alignRight: false },
  { id: 'Actions', label: '', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array = [{}], comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

// const validationSchemaClientResetPassword = yup.object({
//   client_password: yup
//     .string('Enter the password')
//     .required('Password is required')
//     .max(100, `Password can't be more than 100 characters`),
//   client_password2: yup
//     .string('Confirm the new password')
//     .required('Confirm new password is required')
//     .max(100, `Confoirm password can't be more than 100 characters`),
// });

const validationSchemaClient = yup.object({
  email: yup
    .string('Enter the email')
    .email('Please enter a valid email address')
    .required('Email is required')
    .test(
      'is-valid',
      () => `Please enter a valid email address`,
      (value) => (value ? isEmail(value) : new yup.ValidationError('Please enter a valid email address'))
    )
    .max(100, `Email can't be more than 100 characters`),
  state: yup.string('Enter the state').required('State is required').max(200, `State can't be more than 50 characters`),
  city: yup.string('Enter the city').required('City is required').max(50, `City can't be more than 50 characters`),
  zipcode: yup
    .number('Enter the zip code')
    .required('Zip code is required')
    .test('maxDigits', 'Zip code should have 5 digits', (value) => {
      if (value) {
        return value.toString().length === 5;
      }
      return true;
    }),
  address: yup
    .string('Enter the address')
    .required('Address is required')
    .max(200, `Address can't be more than 200 characters`),
  phone: yup
    .string('Enter the phone number')
    .required('Phone number is required')
    .test('maxDigits', 'Phone number should have 10 digits', (value) => {
      if (value) {
        const cleanedValue = value.replace(/\D/g, '');
        return cleanedValue.length === 10;
      }
      return true;
    }),
});

export default function ClientsPage() {
  const [credentialsLoader, setCredentialsLoader] = useState(false);
  const [agreementsLoader, setAgreementsLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [confirmLoader, setConfirmLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [clientAccessCredentialsModalOpen, setClientAccessCredentialsModalOpen] = useState(false);
  const [clientContractsModalOpen, setClientContractsModalOpen] = useState(false);
  const [clientLockAccountModalOpen, setClientLockAccountModalOpen] = useState(false);
  const [clientResetPasswordModalOpen, setClientResetPasswordModalOpen] = useState(false);
  const [clientEditAccountModalOpen, setClientEditAccountModalOpen] = useState(false);
  const [clientAccessCredentials, setClientAccessCredentials] = useState([]);
  const [clientContracts, setClientContracts] = useState([]);
  const [selectedClientAccountStatus, setSelectedClientAccountStatus] = useState('');
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selectedClientAction, setSelectedClientAction] = useState('');

  const [selectedClientId, setSelectedClientId] = useState('');

  const [selectedClient, setSelectedClient] = useState({});

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const filteredClients = applySortFilter(clients, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredClients.length && !!filterName;

  const [anchorEl, setAnchorEl] = useState({});

  const clearSelectedAccount = () => {
    setSelectedClientAction('');
    setSelectedClientId('');
    setSelectedClientAccountStatus('');
  };

  const handleActionClick = (event, menuId) => {
    setAnchorEl({ [menuId]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl({});
  };

  const formikResetPassword = useFormik({
    initialValues: {
      client_password: '',
      client_password2: '',
    },
    // validationSchema: validationSchemaClientResetPassword,
    onSubmit: async (values) => {
      setLoading(true);
      values.client_id = selectedClientId;
      await updateClientPasswordAdmin(values)
        .then(() => {
          const message = 'Client password successfully updated.';
          toast.success(message);
        })
        .catch((error) => {
          console.log('error', error);
          toast.error('Error updating password. Try again later.');
        })
        .finally(() => {
          setLoading(false);
          handleClientResetPasswordModalClose();
        });
    },
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      zipcode: '',
      city: '',
      state: '',
      address: '',
    },
    validationSchema: validationSchemaClient,
    onSubmit: async (values) => {
      setLoading(true);
      await editClientInfo(values)
        .then(() => {
          const message = 'Client info successfully updated.';
          toast.success(message);
        })
        .catch((error) => {
          console.log('error', error);
          toast.error('Error updating client info. Try again later.');
        })
        .finally(() => {
          setLoading(false);
          handleClientEditAccountModalClose();
        });
    },
  });
  const handleFieldBlur = (fieldName) => {
    formik.setFieldTouched(fieldName, true, true);
  };

  const handleClientCredentialsModalOpen = () => {
    setCredentialsLoader(true);
    getClientAccessCredentials({ client_id: selectedClientId })
      .then((response) => {
        setClientAccessCredentials(response.data.credentials);
      })
      .catch((error) => {
        console.log('Error getting client access credentials.', error);
        toast.error('Unable to get client access credentials.');
      })
      .finally(() => {
        setCredentialsLoader(false);
      });
    setClientAccessCredentialsModalOpen(true);
  };

  const handleClientContractsModalOpen = () => {
    setAgreementsLoader(true);
    getClientContracts({ client_id: selectedClientId })
      .then((response) => {
        setClientContracts(response.data.contracts);
      })
      .catch((error) => {
        console.log('Error getting client access credentials.', error);
        toast.error('Unable to get client access credentials.');
      })
      .finally(() => {
        setAgreementsLoader(false);
      });
    setClientContractsModalOpen(true);
  };

  const handleResetClientPasswordModalOpen = () => {
    setClientResetPasswordModalOpen(true);
  };

  const handleClientLockAccountModalOpen = () => {
    setClientLockAccountModalOpen(true);
  };

  const handleClientLockAccountModalClose = () => {
    setClientLockAccountModalOpen(false);
    clearSelectedAccount();
  };

  const handleClientEditAccountModalOpen = () => {
    setClientEditAccountModalOpen(true);
    formik.setValues(selectedClient);
  };

  const handleClientEditAccountModalClose = () => {
    setClientEditAccountModalOpen(false);
    clearSelectedAccount();
  };

  const handleClientCredentialsModalClose = () => {
    setClientAccessCredentialsModalOpen(false);
    clearSelectedAccount();
  };

  const handleClientContractsModalClose = () => {
    setClientContractsModalOpen(false);
    clearSelectedAccount();
  };

  const handleClientResetPasswordModalClose = () => {
    formikResetPassword.resetForm();
    setClientResetPasswordModalOpen(false);
    clearSelectedAccount();
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = clients.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleContractDownload = (id) => {
    getClientContractFile({ id })
      .then((response) => {
        window.open(response.data.url, '_blank');
      })
      .catch(() => {
        const message = 'Error downloading contract. Try again later.';
        toast.error(message);
      });
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredClients?.length) : 0;
  useEffect(() => {
    setPageLoader(true);
    const userGroups = getUserGroups();
    if (!userGroups.includes('admin')) {
      navigate('/dashboard', { replace: true });
    } else {
      getClients()
        .then((clients) => {
          setClients(clients.data);
        })
        .catch(() => {
          const message = 'Cannot get clients, try again later.';
          toast.error(message);
        })
        .finally(() => {
          setPageLoader(false);
        });
    }
    if (selectedClientAction !== '') {
      switch (selectedClientAction) {
        case 'showCredentials':
          handleClientCredentialsModalOpen();
          break;
        case 'showContracts':
          handleClientContractsModalOpen();
          break;
        case 'createContract':
          navigate('/client-contracts/new', { replace: true });
          break;
        case 'resetClientPassword':
          handleResetClientPasswordModalOpen();
          break;
        case 'lockAccount':
          handleClientLockAccountModalOpen();
          break;
        case 'editAccount':
          handleClientEditAccountModalOpen();
          break;
        case 'downloadW9Copy':
          handleClientDownloadW9Copy();
          break;
        default:
          break;
      }
    }
  }, [selectedClientAction]);

  const handleClientDownloadW9Copy = async () => {
    await getClientW9Copy({ client_id: selectedClientId })
      .then((response) => {
        window.open(response.data.url, '_blank');
      })
      .catch(() => {
        const message = 'Error downloading W9 copy. Try again later.';
        toast.error(message);
      })
      .finally(() => {
        clearSelectedAccount();
      });
  };

  const handleClientActions = (action, clientId, clientStatus, client) => {
    setSelectedClient(client);
    setSelectedClientId(clientId);
    setSelectedClientAction(action);
    setSelectedClientAccountStatus(clientStatus);
  };

  const handleClientLockAccount = () => {
    setConfirmLoader(true);
    if (selectedClientAccountStatus === 'Active') {
      lockClientAccount({ client_id: selectedClientId })
        .then(() => {
          const message = 'Client account has been locked.';
          toast.success(message);
        })
        .catch(() => {
          const message = 'Error locking client account. Try again later.';
          toast.error(message);
        })
        .finally(() => {
          setConfirmLoader(false);
          handleClientLockAccountModalClose();
        });
    }
    if (selectedClientAccountStatus === 'Locked') {
      unlockClientAccount({ client_id: selectedClientId })
        .then(() => {
          const message = 'Client account has been unlocked.';
          toast.success(message);
        })
        .catch(() => {
          const message = 'Error unlocking client account. Try again later.';
          toast.error(message);
        })
        .finally(() => {
          handleClientLockAccountModalClose();
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | Clients</title>
      </Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Box>
              <Typography variant="h4" gutterBottom>
                Client Directory
              </Typography>
              <Typography variant="p">Oversee and manage all clients.</Typography>
            </Box>
            <LightTooltip title="Add a new client to the directory.">
              <Button
                variant="contained"
                onClick={() => navigate('/new-client')}
                startIcon={<Iconify icon="eva:plus-fill" />}
                style={{
                  backgroundColor: '#0A9EF2',
                }}
              >
                New Client
              </Button>
            </LightTooltip>
          </Stack>

          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={filteredClients.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  {pageLoader ? (
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredClients.length === 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="body2">No records found.</Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )
                  )}

                  <TableBody>
                    {filteredClients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const { id, username, email, practice_name, phone, status, steps, w9_copy_exists } = row;
                      const selectedUser = selected.indexOf(email) !== -1;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, email)} />
                          </TableCell>
                          <TableCell align="left">{practice_name}</TableCell>
                          <TableCell align="left">{username}</TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{phone}</TableCell>
                          <TableCell align="left">{status}</TableCell>
                          <TableCell align="left">
                            {steps !== undefined && steps.length === 0 && '-'}
                            {steps !== undefined &&
                              steps.map((step) => (
                                <div key={step.id} style={{ display: 'flex', alignItems: 'center' }}>
                                  <img
                                    src={step.status === 'Done' ? '/assets/iconDone.png' : '/assets/iconProcess.png'}
                                    alt={step.status}
                                    height={'20px'}
                                    width={'20px'}
                                  />
                                  <p style={{ margin: '0px 0px 0px 5px' }}>
                                    {step.name}
                                    {step.remarks ? `: ${step.remarks}` : ''}
                                  </p>
                                </div>
                              ))}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              '.MuiTableCell-root': {
                                padding: '0px',
                              },
                            }}
                          >
                            <TableCell align="left">
                              <IconButton
                                aria-controls="client-actions-menu"
                                aria-haspopup="true"
                                onClick={(e) => {
                                  handleActionClick(e, index);
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id="client-actions-menu"
                                anchorEl={anchorEl[index]}
                                open={Boolean(anchorEl[index])}
                                onClose={handleClose}
                              >
                                <MenuItem
                                  style={{ display: 'flex', alignItems: 'center' }}
                                  onClick={() => handleClientActions('showCredentials', id, status, row)}
                                >
                                  <img
                                    src={'/assets/icons8-eye 1.png'}
                                    alt=""
                                    height={'20px'}
                                    width={'20px'}
                                    style={{ marginRight: '8px' }}
                                  />
                                  View Client Credentials
                                </MenuItem>
                                <MenuItem
                                  style={{ display: 'flex', alignItems: 'center' }}
                                  onClick={() => handleClientActions('resetClientPassword', id, status, row)}
                                >
                                  <img
                                    src={'/assets/icons8-lock_orientation 2.png'}
                                    alt=""
                                    height={'20px'}
                                    width={'20px'}
                                    style={{ marginRight: '8px' }}
                                  />
                                  Reset Password
                                </MenuItem>
                                <MenuItem
                                  style={{ display: 'flex', alignItems: 'center' }}
                                  onClick={() => handleClientActions('showContracts', id, status, row)}
                                >
                                  <img
                                    src={'/assets/icons8-download 1.png'}
                                    alt=""
                                    height={'20px'}
                                    width={'20px'}
                                    style={{ marginRight: '8px' }}
                                  />
                                  See Agreements
                                </MenuItem>
                                <MenuItem
                                  style={{ display: 'flex', alignItems: 'center' }}
                                  onClick={() => handleClientActions('createContract', id, status, row)}
                                >
                                  <img
                                    src={'/assets/icons8-create-64.png'}
                                    alt=""
                                    height={'20px'}
                                    width={'20px'}
                                    style={{ marginRight: '8px' }}
                                  />
                                  Create Agreement
                                </MenuItem>
                                {w9_copy_exists && (
                                  <MenuItem
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    onClick={() => handleClientActions('downloadW9Copy', id, status, row)}
                                  >
                                    <img
                                      src={'/assets/icons8-download 1.png'}
                                      alt=""
                                      height={'20px'}
                                      width={'20px'}
                                      style={{ marginRight: '8px' }}
                                    />
                                    Download W9 Copy
                                  </MenuItem>
                                )}
                                <MenuItem
                                  style={{ display: 'flex', alignItems: 'center' }}
                                  onClick={() => handleClientActions('editAccount', id, status, row)}
                                >
                                  <img
                                    src={'/assets/icons8-edit 1.png'}
                                    alt=""
                                    height={'20px'}
                                    width={'20px'}
                                    style={{ marginRight: '8px' }}
                                  />
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  style={{ display: 'flex', alignItems: 'center' }}
                                  onClick={() => handleClientActions('lockAccount', id, status, row)}
                                >
                                  <img
                                    src={'/assets/icons8-lock 1.png'}
                                    alt=""
                                    height={'20px'}
                                    width={'20px'}
                                    style={{ marginRight: '8px' }}
                                  />
                                  {status === 'Active' ? 'Lock' : 'Unlock'}
                                </MenuItem>
                              </Menu>
                            </TableCell>
                            <Dialog open={clientLockAccountModalOpen} onClose={handleClientLockAccountModalClose}>
                              <DialogTitle>Confirmation</DialogTitle>
                              <DialogContent>
                                <p>
                                  Are you sure you want to{' '}
                                  {selectedClientAccountStatus === 'Active' ? 'lock' : 'unlock'} the client account?
                                </p>
                              </DialogContent>
                              <DialogActions sx={{ padding: 2 }}>
                                <Button onClick={handleClientLockAccountModalClose} color="primary">
                                  Cancel
                                </Button>

                                <LoadingButton
                                  onClick={handleClientLockAccount}
                                  color="primary"
                                  variant="contained"
                                  loading={confirmLoader}
                                  loadingPosition="start"
                                  startIcon={confirmLoader ? <CircularProgress size={20} /> : null}
                                >
                                  Confirm
                                </LoadingButton>
                              </DialogActions>
                            </Dialog>

                            <Dialog
                              open={clientEditAccountModalOpen}
                              onClose={handleClientEditAccountModalClose}
                              fullWidth
                            >
                              <DialogTitle>Edit Client Account</DialogTitle>
                              <DialogContent>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} md={12} lg={12}>
                                    <form onSubmit={formik.handleSubmit}>
                                      <Stack spacing={1} paddingTop={1}>
                                        <TextField
                                          id="email"
                                          name="email"
                                          label="Email"
                                          value={formik.values.email}
                                          onChange={formik.handleChange}
                                          onBlur={() => handleFieldBlur('email')}
                                          error={formik.touched.email && Boolean(formik.errors.email)}
                                          helperText={formik.touched.email && formik.errors.email}
                                        />
                                        <TextField
                                          id="phone"
                                          name="phone"
                                          label="Phone"
                                          value={formik.values.phone}
                                          onChange={formik.handleChange}
                                          onBlur={() => handleFieldBlur('phone')}
                                          error={formik.touched.phone && Boolean(formik.errors.phone)}
                                          helperText={formik.touched.phone && formik.errors.phone}
                                        />
                                        <TextField
                                          id="zipcode"
                                          name="zipcode"
                                          label="Zip Code"
                                          value={formik.values.zipcode}
                                          onChange={formik.handleChange}
                                          onBlur={() => handleFieldBlur('zipcode')}
                                          error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
                                          helperText={formik.touched.zipcode && formik.errors.zipcode}
                                        />
                                        <TextField
                                          id="state"
                                          name="state"
                                          label="State"
                                          select
                                          value={formik.values.state}
                                          onChange={formik.handleChange}
                                          onBlur={() => handleFieldBlur('state')}
                                          error={formik.touched.state && Boolean(formik.errors.state)}
                                          helperText={formik.touched.state && formik.errors.state}
                                        >
                                          {USA_STATES.map((state) => (
                                            <MenuItem key={state.abbreviation} value={state.abbreviation}>
                                              {state.name}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                        <TextField
                                          id="city"
                                          name="city"
                                          label="City"
                                          value={formik.values.city}
                                          onChange={formik.handleChange}
                                          onBlur={() => handleFieldBlur('city')}
                                          error={formik.touched.city && Boolean(formik.errors.city)}
                                          helperText={formik.touched.city && formik.errors.city}
                                        />
                                        <TextField
                                          id="address"
                                          name="address"
                                          label="Address"
                                          value={formik.values.address}
                                          onChange={formik.handleChange}
                                          onBlur={() => handleFieldBlur('address')}
                                          error={formik.touched.address && Boolean(formik.errors.address)}
                                          helperText={formik.touched.address && formik.errors.address}
                                        />
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                          justifyContent="flex-end"
                                          mb={5}
                                          spacing="5px"
                                        >
                                          <Button onClick={handleClientEditAccountModalClose} color="primary">
                                            Cancel
                                          </Button>
                                          <Typography variant="h4" gutterBottom />
                                          <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            loading={loading}
                                            loadingPosition="start"
                                            startIcon={loading ? <CircularProgress size={20} /> : null}
                                          >
                                            Edit Client
                                          </LoadingButton>
                                        </Stack>
                                      </Stack>
                                    </form>
                                  </Grid>
                                </Grid>
                              </DialogContent>
                            </Dialog>

                            <Dialog
                              open={clientResetPasswordModalOpen}
                              onClose={handleClientResetPasswordModalClose}
                              fullWidth
                            >
                              <DialogTitle>Reset Client Password</DialogTitle>
                              <DialogContent>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} md={12} lg={12}>
                                    <form onSubmit={formikResetPassword.handleSubmit}>
                                      <Stack spacing={1} paddingTop={1}>
                                        <Typography variant="p">
                                          Do you want to send a one-time password to the client?
                                        </Typography>
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                          justifyContent="flex-end"
                                          mb={5}
                                          spacing="5px"
                                        >
                                          <Button onClick={handleClientResetPasswordModalClose} color="primary">
                                            Cancel
                                          </Button>
                                          <Typography variant="h4" gutterBottom />
                                          <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            loading={loading}
                                            loadingPosition="start"
                                            startIcon={loading ? <CircularProgress size={20} /> : null}
                                          >
                                            Reset Password
                                          </LoadingButton>
                                        </Stack>
                                      </Stack>
                                    </form>
                                  </Grid>
                                </Grid>
                              </DialogContent>
                            </Dialog>

                            <Dialog
                              maxWidth={false}
                              open={clientAccessCredentialsModalOpen}
                              onClose={handleClientCredentialsModalClose}
                            >
                              <DialogTitle>Credentials</DialogTitle>
                              <DialogContent>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Name</TableCell>
                                      <TableCell>Platform</TableCell>
                                      <TableCell>Username</TableCell>
                                      <TableCell>Password</TableCell>
                                      <TableCell>URL</TableCell>
                                      <TableCell>Remarks</TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    {credentialsLoader ? (
                                      <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                          <CircularProgress />
                                        </TableCell>
                                      </TableRow>
                                    ) : clientAccessCredentials.length === 0 ? (
                                      <TableRow>
                                        <TableCell align="center" colSpan={6}>
                                          <Typography variant="body2">No records found.</Typography>
                                        </TableCell>
                                      </TableRow>
                                    ) : (
                                      clientAccessCredentials.map((row) => (
                                        <TableRow key={row.id}>
                                          <TableCell>{row.name}</TableCell>
                                          <TableCell>{row.platform_name}</TableCell>
                                          <TableCell>{row.username}</TableCell>
                                          <TableCell>{row.password}</TableCell>
                                          <TableCell>{row.url}</TableCell>
                                          <TableCell>{row.remarks}</TableCell>
                                        </TableRow>
                                      ))
                                    )}
                                  </TableBody>
                                </Table>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClientCredentialsModalClose} color="primary">
                                  Close
                                </Button>
                              </DialogActions>
                            </Dialog>

                            <Dialog
                              maxWidth={false}
                              open={clientContractsModalOpen}
                              onClose={handleClientContractsModalClose}
                            >
                              <DialogTitle>Agreements</DialogTitle>
                              <DialogContent>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Individuals</TableCell>
                                      <TableCell>Services</TableCell>
                                      <TableCell>Percentage Terms</TableCell>
                                      <TableCell>Activation Date</TableCell>
                                      <TableCell>Created On</TableCell>
                                      <TableCell>Status</TableCell>
                                      <TableCell>Actions</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {agreementsLoader ? (
                                      <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                          <CircularProgress />
                                        </TableCell>
                                      </TableRow>
                                    ) : clientContracts.length === 0 ? (
                                      <TableRow>
                                        <TableCell align="center" colSpan={7}>
                                          <Typography variant="body2">No records found.</Typography>
                                        </TableCell>
                                      </TableRow>
                                    ) : (
                                      clientContracts.map((row) => (
                                        <TableRow key={row.id}>
                                          <TableCell>{row.covered_individuals.join(', ')}</TableCell>
                                          <TableCell>{row.services.join(', ')}</TableCell>
                                          <TableCell>{row.percentage_terms}</TableCell>
                                          <TableCell>{format(parseISO(row.activation_date), 'MM-dd-yy')}</TableCell>
                                          <TableCell>{format(parseISO(row.created_at), 'MM-dd-yy')}</TableCell>
                                          <TableCell>{row.status}</TableCell>
                                          <TableCell>
                                            <LightTooltip title="Print/Download the agreement.">
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleContractDownload(row.id)}
                                              >
                                                <DownloadIcon />
                                              </Button>
                                            </LightTooltip>
                                          </TableCell>
                                        </TableRow>
                                      ))
                                    )}
                                  </TableBody>
                                </Table>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClientContractsModalClose} color="primary">
                                  Close
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={clients?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>

          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem disabled>
              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
              Edit
            </MenuItem>

            <MenuItem sx={{ color: 'error.main' }} disabled>
              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
              Delete
            </MenuItem>
          </Popover>
        </Grid>
      </Grid>
    </>
  );
}
