import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import ContractsPage from './pages/ContractsPage';
import ClientContractsPage from './pages/ClientContractsPage';
import DashboardPage from './pages/DashboardPage';
import LoginPage from './pages/LoginPage';
import NewClientPage from './pages/NewClientPage';
import VeridocPage from './pages/VeridocPage';
import Page404 from './pages/Page404';
import NewClientContractPage from './pages/NewClientContractPage';
import NewQrCodePage from './pages/NewQrCodePage';
import PracticeInfoPage from './pages/PracticeInfoPage';
import ClientsPage from './pages/ClientsPage';
import AuditTrail from './pages/AuditTrail';
import SupportTicket from './pages/SupportTickets';
import NewSupportTicket from './pages/NewSupportTicket';
import SupportTicketReply from './pages/Reply';
import NewPasswordPage from './pages/NewPasswordPage';
import FirstLoginPasswordPage from './pages/FirstLoginPasswordPage';
import ShowContractPage from './pages/ShowContractPage';
import SignupPage from './pages/SignupPage';
import DmrDashboard from './pages/DmrDashboard';
import DmrPage from './pages/DmrPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: 'dashboard', element: <DashboardPage /> },
        { path: 'dmr-dashboard', element: <DmrDashboard /> },
        { path: 'dmr-page', element: <DmrPage /> },
        { path: 'clients', element: <ClientsPage /> },
        { path: 'audit-trail', element: <AuditTrail /> },
        { path: 'practice-info', element: <PracticeInfoPage /> },
        { path: 'contracts', element: <ContractsPage /> },
        { path: 'client-contracts', element: <ClientContractsPage /> },
        { path: 'contracts/:id', element: <ShowContractPage /> },
        // { path: 'sign-agreement', element: <SignAgreementPage /> },
        { path: 'contracts/new', element: <NewClientContractPage /> },
        { path: 'client-contracts/new', element: <NewClientContractPage /> },
        { path: 'veridoc/new', element: <NewQrCodePage /> },
        { path: 'support-tickets', element: <SupportTicket /> },
        { path: 'support-tickets/new', element: <NewSupportTicket /> },
        { path: 'support-tickets/:id', element: <SupportTicketReply /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'new-password/:uid/:token',
      element: <NewPasswordPage />,
    },
    {
      path: 'first-login-password',
      element: <FirstLoginPasswordPage />,
    },

    {
      path: 'new-client',
      element: <NewClientPage />,
    },
    {
      path: 'signup',
      element: <SignupPage />,
    },
    {
      path: 'veridoc/:id',
      element: <VeridocPage />,
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
