import { useNavigate } from 'react-router-dom';
import { TextField, Stack, Typography, Card, Button, CircularProgress, Box, IconButton } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { createSupportTicket } from '../services/api';
import { getUserGroups } from '../utils';

const validationSchema = yup.object({
  subject: yup.string().required('Subject is required'),
  description: yup.string().required('description is required'),
  file: yup.mixed(),
});

export default function NewSupportTicket() {
  const [isClient, setIsClient] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      subject: '',
      description: '',
      file: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const response = await createSupportTicket(values);
      if (response.status === 201) {
        setLoading(false);
        navigate('/support-tickets');
        toast.success('Support ticket created successfully');
      } else {
        setLoading(false);
        toast.error('Failed to create support ticket.');
      }
    },
  });

  const handleFieldBlur = (fieldName) => {
    formik.setFieldTouched(fieldName, true, true);
  };

  useEffect(() => {
    const userGroups = getUserGroups();
    if (!userGroups.includes('admin') && !userGroups.includes('associate')) {
      setIsClient(true);
    } else {
      setIsClient(false);
    }
  }, []);

  const formikSetFile = (event) => {
    // set it to accommodate multiple files
    // check if file is less than 5MB
    console.log('file size', event.currentTarget.files[0].size);
    console.log('file type', event.currentTarget.files[0].type);
    if (event.currentTarget.files[0].size > 5000000) {
      toast.error('File size should be less than 5MB');
    }
    // check if file is of type pdf, png, jpg, jpeg, txt, csv and doc
    else if (
      ![
        'application/pdf',
        'image/png',
        'image/jpg',
        'image/jpeg',
        'text/plain',
        'text/csv',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ].includes(event.currentTarget.files[0].type)
    ) {
      toast.error('File type should be pdf, png, jpg, jpeg, txt, csv or doc');
    } else {
      formik.setFieldValue('file', event.currentTarget.files[0]);
    }
    // formik.setFieldValue('file', event.currentTarget.files);
  };

  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | Support Ticket</title>
      </Helmet>

      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5rem' }}>
        <IconButton onClick={() => navigate('/support-tickets')}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">New Support Ticket</Typography>
      </div>
      {isClient ? (
        <Card sx={{ maxWidth: 750, padding: 5 }}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <TextField
                id="subject"
                name="subject"
                label="Subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                onBlur={() => handleFieldBlur('subject')}
                error={formik.touched.subject && Boolean(formik.errors.subject)}
              />
              <TextField
                id="description"
                name="description"
                label="Description"
                multiline
                rows={4}
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={() => handleFieldBlur('description')}
                error={formik.touched.description && Boolean(formik.errors.description)}
              />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  type="file"
                  id="fileUpload"
                  name="fileUpload"
                  onChange={formikSetFile}
                  label="Attachments"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  fullWidth
                />
              </div>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="start" spacing={1} mb={5} mt={5}>
              <Typography variant="h4" gutterBottom />
              <LoadingButton
                sx={{
                  backgroundColor: '#0A9EF2',

                  minWidth: '100px',
                }}
                type="submit"
                variant="contained"
                loading={loading ? 'submit' : false}
                loadingPosition="start"
                startIcon={
                  loading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CircularProgress size={20} sx={{ marginRight: 0.5 }} />
                    </Box>
                  ) : null
                }
              >
                Submit
              </LoadingButton>
              <Button
                variant="outlined"
                sx={{ color: '#0A9EF2', borderColor: '#0A9EF2', minWidth: '100px' }}
                onClick={() => navigate('/support-tickets')}
              >
                Cancel
              </Button>
            </Stack>
          </form>
        </Card>
      ) : null}
    </>
  );
}
