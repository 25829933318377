import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Container, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// components
import Logo from '../components/logo';
// sections
import NewClientForm from '../sections/NewClientForm';
import { getUserGroups } from '../utils';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function NewClientPage() {
  const navigate = useNavigate();
  useEffect(() => {
    const userGroups = getUserGroups();
    if (!userGroups || !userGroups.includes('admin', 'associate')) {
      navigate('/login', { replace: true });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>BillerBay | New Client</title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container maxWidth="sm">
          <StyledContent>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
              <IconButton onClick={() => navigate('/clients')}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h4">Onboard New Client</Typography>
            </div>

            <Typography gutterBottom>
              Enter the details to create a new client account. The temporary password will be sent to the client's
              email address.
            </Typography>

            <NewClientForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
